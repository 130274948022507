import React, {useState, useEffect, useRef} from 'react';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import  Header  from "../../components/Header";
import { delayedRedirect, redirect } from '../../../utils/response';
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

export const VerifyEmail = () => {
    const FPSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email Required"),
    });
    // let { patientId, code, state } = useParams();
    const dispatch = useDispatch()
    const location = useLocation()
    const isMounted = useRef(false);
    const [showResend, setShowResend] = useState(false);
    const [user, setUser] = useState({email:''});

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const token = queryParams.get('token');
        console.log("tokeen ==", token)
        // const code = queryParams.get('code')
        // const state = queryParams.get('state')
        // console.log("patientId =", patientId, "code =", code, "state ==",state);
        if (token) {
            dispatch({
                type: authActions.verifyToken.type,
                payload: {
                    data: {
                        token
                    },
                    callback: (token) => {
                        delayedRedirect(`/login`)
                    },
                    errorcallback: (token) => {
                        setShowResend(true)
                    }
                }
            })
        }else {
            isMounted.current = true;
        }
    },[])

    const resendLink = (values) => {
        dispatch({
            type: authActions.resendVerificationLink.type,
            payload: {
                data: values,
                callback: (token) => {
                    delayedRedirect(`/login`)
                    
                }
            }
        })
    }

    return (
        <div className="font-Poppins bg-green-100 w-full h-screen">
            <Header/>
            {
                showResend  && (
                     <div className="flex flex-wrap mt-0 w-full bg-green-100 h-full">
                <div className="w-full md:w-1/2 lg:w-1/2 z-[100] mt-10 p-4 pb-16 md:pl-16 lg:pl-16 md:p-0 lg:p-0">
                    
                       
                        <Formik
                        validationSchema={FPSchema}
                        initialValues = {user}
                        onSubmit={(values) => {
                            resendLink(values);
                    }}
                    >
                    {
                        ({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            handleChange,
                            setValues,
                        }) => (
                            <Form>
                                <div className="bg-white rounded-2xl p-5 w-full px-10 pt-20 pb-32">
                                    <h1 className="font-bold text-xl text-gray-600">Resend Verification link</h1>
                                    <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                                    <p className="py-3 text-gray-500 text-lg">Account's email for which verification needs to be done!</p>
    
                                    
                                        <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-5 ">
                                            <p className="text-gray-500 text-md font-bold">Email</p>
                                            <Field
                                                className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                type="text"
                                                name="email"
                                                value={values?.email}
                                                required
                                                placeholder=""
                                            />
                                            {errors.email && touched.email ? (
                                            <p className="mt-2 text-red-500 text-xs">{errors.email}</p>
                                        ) : null}
                                        </div>
                                       
                                
                                    <div className="font-semibold text-gray-400 flex gap-x-5 items-center mt-10 w-full justify-end">
                                        <button type="submit" className="font-bold text-white bg-green-200 py-1 px-8 rounded-md">{`Send >`}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                    </Formik>
                   
                    
                
                </div>
                <div className="hidden md:block lg:block w-full md:w-1/2 lg:w-1/2 mt-10">
                    {/* <img src="/images/splash.png"  /> */}
                    <div className="z-0 w-full flex justify-between relative">
                        <img src="/images/cloud_1.png" className="z-0 w-2/6 h-42 object-contain absolute pt-52" />
                        <img src="/images/zues.png"  className="w-4/6 h-80 object-contain pt-20"/>
                        <img src="/images/cloud_2.png" className="z-0 w-2/6 h-42 object-contain pt-96" />
                    </div>
                    <div className="lg:-mt-52 md:-mt-44 z-[100] relative">
                        <h1 className=" text-center text-orange-200 font-bold text-4xl">The mighty<br/> ZuesRx extension</h1>
                        <h1 className="text-center text-green-200 font-bold text-lg mt-2">simply makes the most of your sheets!</h1>
                    </div>
                    
                </div>
            </div>
           
                )
            }
        </div>
    )

}

