import React, {useState, useEffect} from 'react';
import authService from "../../../services/authService";
import userService from "../../../services/userService";
import { redirect } from '../../../utils/response';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './../../shared-redux/Auth/slice';

const Header = (props) => {
    
    const dispatch = useDispatch();
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [refreshToken, setrefreshToken] = useState()
    const [dashboardVisible, setDashboardVisible] = useState(true)
    const [active, setActive] = useState(props.active)

    const checkLoggedInUser = async() => {
        const refreshToken = await userService.getUserToken('refresh');
        // console.log("refreshToken ==", refreshToken)
        if(refreshToken) setrefreshToken(refreshToken.token)
        const isAuthenticated = await authService.isAuthenticated();
        setIsLoggedIn(isAuthenticated);
        if(isAuthenticated){
            const dashboardView = await userService.getDashboardVisible();
            setDashboardVisible(dashboardView === "false" ? false : true);
        }
    }

    useEffect(() => {
        checkLoggedInUser();

    },[props]);

    const onLogout = () => {
        dispatch({
            type: actions.logoutUser.type,
            payload: {
                showToast:true,
                data: {
                    refreshToken
                }
            }
        });
    }

    const onAccount = () => {
        redirect('/account')
    }

    const onMighty = () => {
        redirect('/dashboard')
    }


    return(
        <div id="headerID" className="font-Poppins w-full pt-4 lg:p-8 md:p-4 md:flex lg:flex">
            <div className="pl-8 w-fit">
                <a href='/'><img src="/images/full-logo-new.png" className="h-8 md:h-10 lg:h-16 object-contain" /></a>
            </div>
           <div className="flex flex-wrap mt-3 gap-y-2 w-full justify-between my-5 md:my-0 lg:mt-0 px-5 md:px-20 lg:px-20 items-center">
            {
                isLoggedIn && (
                    <div className="flex gap-x-5 items-center">
                        <div onClick={onAccount}  className={`cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-5 py-2 text-xs ${active === 0 ? 'bg-orange-200' : 'bg-orange-100'} text-white rounded-md h-8`}>
                            <img className="h-4" src="/images/user-icon-logged.png"  />
                            Account
                            </div>
                            {
                                dashboardVisible && (
                                <div onClick={onMighty} className={`cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-5 py-2 text-xs ${(active == 1) ? 'bg-orange-200' : 'bg-orange-100'} text-white rounded-md h-8`}>
                                    <img className="h-4" src="/images/mighty.png"  />
                                    Dashboard
                                </div>
                                )
                            }
                       
                    </div>
                )
            }
            {
                (isLoggedIn && (!location.pathname.includes('acknowledgement'))) && (
                    <div onClick={() => onLogout()} className="shadow-lg font-bold cursor-pointer px-5 py-2 text-xs bg-green-200 text-white rounded-md h-8">{`> Logout`}</div>
                )
            }{
                (!isLoggedIn) && (
                    <div className="flex items-center gap-x-2 shadow-lg font-bold px-6 py-2 text-xs bg-orange-200 text-white rounded-md h-8">
                        <img className="h-4" src="/images/account_white.png"  />
                        Account
                    </div>
                )
            }
           </div>
            
        </div>
        
    )
}

export default Header;