import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
    isLoading: false,
    success: false,
    error: '',
    user: {},
    patientList:[],
    uneditable:[],
    defaultAvailableColumns:[]
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // register user
        registerUser() { },
        registerUserLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        registerUserSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            // state.user = action.payload;
        },
        registerUserFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        registerUserWithAzure() { },

        // register user
        completeRegistration() { },
        completeRegistrationLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        completeRegistrationSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        completeRegistrationFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // verify email
        emailVerification() { },
        emailVerificationLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        emailVerificationSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        emailVerificationFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // login user
        loginGoogleUser() { },
        loginUser() {
         },
        loginUserLoading(state, action) {
            state.loading = false;
            state.user = {};
            state.error = '';
        },
        loginUserSuccess(state, action) {
            state.loading = false;
            state.user = action.payload;
            state.error = '';
        },
        loginUserFailure(state, action) {
            state.loading = false;
            state.user = {};
            state.error = action.paylaod;
        },
        logoutUser() { },

        loginUserWithAzure() { },

        // Reset Password
        requestPasswordReset() { },
        requestPasswordResetLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        requestPasswordResetSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        requestPasswordResetFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

        // Reset Password
        resetPassword() { },
        resetPasswordLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        resetPasswordSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        resetPasswordFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },

         // Reset Password
         forgotPassword() { },
         forgotPasswordLoading(state, action) {
             state.isLoading = true;
             state.error = '';
         },
         forgotPasswordSuccess(state, action) {
             state.isLoading = false;
             state.error = '';
         },
         forgotPasswordFailure(state, action) {
             state.isLoading = false;
             state.error = action.payload;
             state.user = {};
         },

        // send OTP
        sendOTP() { },
        sendOTPLoading(state, action) {
            state.loading = true;
        },
        sendOTPSuccess(state, action) {
            state.loading = false;
        },
        sendOTPFailure(state, action) {
            state.loading = false;
        },

        // verify OTP
        verifyOTP() { },
        verifyOTPLoading(state, action) {
            state.loading = true;
        },
        verifyOTPSuccess(state, action) {
            state.loading = false;
        },
        verifyOTPFailure(state, action) {
            state.loading = false;
        },

        // change password
        changePassword() { },
        changePasswordLoading(state, action) {
            state.loading = true;
        },
        changePasswordSuccess(state, action) {
            state.loading = false;
        },
        changePasswordFailure(state, action) {
            state.loading = false;
        },
        updateUser() { },
        updateUserLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        updateUserSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        updateUserFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.user = {};
        },
        getAllPatients() { },
        getAllPatientsLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        getAllPatientsSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
            state.patientList = action.payload.patientList;
            state.uneditable = action.payload.uneditable;
            state.defaultAvailableColumns = action.payload.defaultAvailableColumns;
        },
        getAllPatientsFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        createColumn() { },
        createColumnLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        createColumnSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        createColumnFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        downloadSampleCSV() { },
        downloadSampleCSVLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        downloadSampleCSVSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        downloadSampleCSVFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        downloadClientCSV() { },
        importCSV() { },
        updatePatient() { },
        deletePatient() { },
        deleteAllPatient() { },
        addPatient() { },
        updatePatientLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        updatePatientSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        updatePatientFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        bbAuthorize() { },
        blueButtonRedirect() { },
        bbAuthorizeLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        bbAuthorizeSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        bbAuthorizeFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        verifyToken() { },
        verifyTokenLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        verifyTokenSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        verifyTokenFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        resendVerificationLink() { },
        fetchBatch() { },
        authenticatePatients() { },
        authenticatePatientsLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        authenticatePatientsSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        authenticatePatientsFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        validateNpn() { },
        validateNpnLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        validateNpnSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        validateNpnFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        fetchPlans() { },
        fetchPlansLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        fetchPlansSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        fetchPlansFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        checkSubscription() { },
        checkSubscriptionLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        checkSubscriptionSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        checkSubscriptionFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initiatePayment() { },
        initiatePaymentLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        initiatePaymentSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        initiatePaymentFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updatePaymentMethod() { },
        getPaymentMethod() { },
        getPaymentMethodLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        getPaymentMethodSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        getPaymentMethodFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        deleteSubscription() { },
        deleteSubscriptionLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        deleteSubscriptionSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        deleteSubscriptionFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getAllFolders() { },
        getAllFoldersLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        getAllFoldersSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        getAllFoldersFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        updateFolder() { },
        updateFolderLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        updateFolderSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        updateFolderFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        moveLabel() { },
        moveLabelLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        moveLabelSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        moveLabelFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        addFolder() { },
        addFolderLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        addFolderSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        addFolderFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getUser() { },
        getUserLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        getUserSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        getUserFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getProcessStatus() { },
        getProcessStatusLoading(state, action) {
            state.isLoading = true;
            state.error = '';
        },
        getProcessStatusSuccess(state, action) {
            state.isLoading = false;
            state.error = '';
        },
        getProcessStatusFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        deleteFolder() { },

        getAllLinks() { },
        createLink() { },
        updateLink() { },
        deleteLink() { },
        exportToCRM() { },
        bulkExportToCRM() { },
        getUserProfile() { }

    },
});

export const { actions, reducer, name: sliceKey } = authSlice;
