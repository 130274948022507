import React, {useState, useEffect} from 'react';
// import {  Input,InputGroup} from 'rsuite';
// import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { CardElement,useElements,useStripe,CardNumberElement,CardCvcElement,CardExpiryElement } from '@stripe/react-stripe-js';
import { actions as authActions } from './../../shared-redux/Auth/slice';
import userService from '../../../services/userService';
// import ConfirmationModel from './../../components/ConfirmationModel';
// import { toast } from 'react-toastify';
// import { redirect } from '../../../utils/response';
import moment from 'moment-timezone';

const Billing = (props) => {    
    const dispatch = useDispatch();
    // const location = useLocation();
    // const elements = useElements()
    // const stripe = useStripe()

    // const [userUpdate, setUserUpdate] = useState({});
    // const [activePlans,setActivePlans] = useState([])
    // const [userPlanInfo,setUserPlanInfo] = useState()
    const [user, setUser] = useState(null);
    // const [selectedPlan,setSelectedPlan] = useState()
    // const [selectedPrice,setSelectedPrice] = useState()
    // const [paymentMethodExists,setPaymentMethodExists] = useState(false)
    // const [payDisabled,setPayDisabled] = useState(true)
    // const [cardInfo,setCardInfo] = useState(false)
    // const [defaultPayInfo,setDefaultPayInfo] = useState()
    // const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    // const [currentPaymentMethod,setCurrentPaymentMethod] = useState()
    // const [showChangeCard, setShowChangeCard] = useState(false)
    const [subscriptionDetails, setSubscriptionDetails] = useState()


    useEffect(() => {
        userService.getUser().then(value => {
            if (value){
                setUser(value);
                checkSubscription(value)
            }
             
          });
    }, [])

    // const getPaymentMethod = () => {
    //     dispatch({
    //         type: authActions.getPaymentMethod.type,
    //         payload: {
    //             successCallback: (data) => {
    //                 setCurrentPaymentMethod(data)
    //                 //setActivePlans(data)
    //             }
    //         }
    //     })
    // }

    // useEffect(() => {
    //   dispatch({
    //         type: authActions.fetchPlans.type,
    //         payload: {
    //             successCallback: (data) => {
    //                 setActivePlans(data)
    //             }
    //         }
    //     })
    //     getPaymentMethod()
    // }, [])

    // useEffect(() => {
    //   if(activePlans && userPlanInfo && userPlanInfo.subscriptionDetails){
    //     if(moment.unix(userPlanInfo?.subscriptionDetails.current_period_end) >= moment(new Date()) && userPlanInfo?.subscriptionDetails.status === 'active'){
    //         const userPlan = activePlans.filter((e)=>userPlanInfo?.subscriptionDetails?.plan?.product === e?.id)
    //         if(userPlan && userPlan[0]){
    //         const userPlanPrice = userPlan[0]?.price?.filter((e)=>userPlanInfo?.subscriptionDetails?.plan?.id === e?.id)
    //         setSelectedPlan(userPlan[0])
    //         setSelectedPrice(userPlanPrice[0])
    //         setDefaultPayInfo(userPlanPrice[0])
    //         }
    //     }

    //   }
    // }, [activePlans,userPlanInfo])

    // const updatePaymentMethod = (paymnentId) => {
    //     dispatch({
    //         type: authActions.updatePaymentMethod.type,
    //         payload: {
    //             data: {
    //                 paymentMethodId:paymnentId
    //             },
    //             successCallback: () => {
    //                 // console.log("updatePaymentMethod ==", data)
    //                 clearCardInfo()
    //                 setShowChangeCard(false)
    //                 getPaymentMethod()
    //                 //setActivePlans(data)
    //             }
    //         }
    //     })
    // }

    // const onnUpdatePaymentMethod = async(e) => {
    //     e.preventDefault();
    //     const cardElement = elements.getElement(CardElement)
    //     const {error,paymentMethod} = await stripe.createPaymentMethod({
    //         type:"card",
    //         card:cardElement
    //     })
    //     if(!error){
    //         const paymentMethodId = paymentMethod.id
    //         updatePaymentMethod(paymentMethodId);
    //     }
    // }

    const checkSubscription = (value,hideLoader = false) => {
        const date = value.createdAt;
        const start = moment(date, 'YYYY-MM-DD');
        const end = moment(new Date(), 'YYYY-MM-DD');
        const diff = end.diff(start, 'days');
        try{
            dispatch({
                type: authActions.checkSubscription.type,
                payload: {
                userId:value.id,
                hideLoader:hideLoader,
                // showToastMsgFor:"signup",
                successCallback: (data) => {
                    setSubscriptionDetails(data)
                    props.setSubscriptionDetails(data)
                    if(data.paymentStatus && data.paymentStatus === "Processing"){
                        userService.setDashboardVisible("false")
                        props.updateHeaderCallback("false")
                        setTimeout(() => {
                            checkSubscription(value, true);
                        },2000)
                    }else if(data.subscriptionDetails){
                        if(data?.subscriptionDetails.status === 'active' && data.paymentStatus === "Completed" && moment.unix(data?.subscriptionDetails.current_period_end) >= moment(new Date())){
                            userService.setDashboardVisible("true")
                            props.updateHeaderCallback("true")
                        }else{
                                if(diff > 30 && data?.subscriptionDetails.status !== 'active'){//} && (moment.unix(data?.subscriptionDetails.current_period_end) < moment(new Date()))){
                                    userService.setDashboardVisible("false")
                                    props.updateHeaderCallback("false")
                                }
                        }
                    }else{
                       
                        if(diff > 30){
                            userService.setDashboardVisible("false")
                            props.updateHeaderCallback("false")
                        }else{
                            userService.setDashboardVisible("true")
                            props.updateHeaderCallback("true")
                        }
                       
                    }
                
                }}
            })
        }catch(e){

        }
       
    };

    // const checkSubscription1 = (value) => {
    //     const date = value.createdAt;
    //     const start = moment(date, 'YYYY-MM-DD');
    //     const end = moment(new Date(), 'YYYY-MM-DD');
    //     const diff = end.diff(start, 'days');
    //     try{
    //         dispatch({
    //             type: authActions.checkSubscription.type,
    //             payload: {
    //                 userId:value.id,
    //                 successCallback: (data) => {
    //                     setUserPlanInfo(data)
    //                     if(data.subscriptionDetails){
    //                         if(data?.subscriptionDetails.status === 'active' && moment.unix(data?.subscriptionDetails.current_period_end) >= moment(new Date())){
    //                             userService.setDashboardVisible("true")
    //                             props.updateHeaderCallback("true")
    //                         }else{
    //                                 if(diff > 30 && data?.subscriptionDetails.status !== 'active'){//} && (moment.unix(data?.subscriptionDetails.current_period_end) < moment(new Date()))){
    //                                     userService.setDashboardVisible("false")
    //                                     props.updateHeaderCallback("false")
    //                                 }
    //                         }
    //                     }else{
    //                         if(diff > 30){
    //                             userService.setDashboardVisible("false")
    //                             props.updateHeaderCallback("false")
    //                         }else{
    //                             userService.setDashboardVisible("true")
    //                             props.updateHeaderCallback("true")
    //                         }
    //                     }
    //                     // console.log("setUserPlanInfo =", data, "diff =", diff)
                       
    //                 },
    //                 errorCallback:() => {
    //                     if(diff > 30){
    //                         userService.setDashboardVisible("false")
    //                         props.updateHeaderCallback("false")
    //                     }else{
    //                         userService.setDashboardVisible("true")
    //                         props.updateHeaderCallback("true")
    //                     }
    //                 }
    //             }
    //         })
    //     }catch(e){

    //     }
        
    // }

    // useEffect(() => {
    //     if(!userUpdate?.name|| !cardInfo ){
    //         setPayDisabled(true)
    //     }else{
    //         setPayDisabled(false)
    //     }
    // }, [userUpdate,cardInfo])

    // const onUpdateValue = (key, value) => {
    //     setUserUpdate({
    //         ...userUpdate,
    //         [key]: value
    //     })
    // }

    // const clearCardInfo = () => {
    //     const cardElement = elements.getElement(CardElement)
    //     cardElement.clear()
    //     setUserUpdate({})
    // }

    // const makePayment = async (price,product) => {
    //     // if(e){e.preventDefault();}
    //     if(!stripe || !elements || !product){
    //         return;
    //     }
    //     if(!currentPaymentMethod || currentPaymentMethod.data?.length < 1 || !currentPaymentMethod.data[0].id) return;
       
    //     try{
    //         const paymentMethodId = currentPaymentMethod.data[0].id
    //         // updatePaymentMethod(paymentMethodId);
    //         if(user.id){
    //             dispatch({
    //             type: authActions.initiatePayment.type,
    //             payload: {
    //                 data:{
    //                     "packageId":price.id,
    //                     "paymentMethodId":paymentMethodId,
    //                     "userId":user.id
    //                 },
    //                 successCallback: (data) => {
    //                     //checkSubscription(user)
    //                         if (data.latest_invoice.payment_intent && data.latest_invoice.payment_intent.status === 'succeeded') {
    //                         const checkSubscription = setInterval(() => {
    //                         try{
    //                             dispatch({
    //                                 type: authActions.checkSubscription.type,
    //                                 payload: {
    //                                 userId:user.id,
    //                                 showToastMsgFor:"signup",
    //                                 successCallback: (data) => {
    //                                 if(data.subscriptionDetails){
    //                                     if(moment.unix(data?.subscriptionDetails.current_period_end) >= moment(new Date()) && data?.subscriptionDetails.status === 'active'){
    //                                         userService.setDashboardVisible("true")
    //                                         props.updateHeaderCallback("true")
    //                                         clearInterval(checkSubscription);
    //                                         setUserPlanInfo(data)
    //                                         // clearCardInfo()
    //                                     }
    //                                 }
    //                             }}
    //                             })
    //                         }catch(e){

    //                         }
                           
    //                         }, 2000);
    //                     }else if (data.status === 'active') {
    //                         try{
    //                             dispatch({
    //                                 type: authActions.checkSubscription.type,
    //                                 payload: {
    //                                 userId:user.id,
    //                                 successCallback: (data) => {
    //                                     setUserPlanInfo(data)
    //                                     // clearCardInfo()
    //                                     if(data?.subscriptionDetails && data?.subscriptionDetails.status === 'active' && moment.unix(data?.subscriptionDetails.current_period_end) >= moment(new Date())){
    //                                         userService.setDashboardVisible("true")
    //                                         props.updateHeaderCallback("true")
    //                                     } 
    //                                     else{
    //                                         const date = user.createdAt;
    //                                         const start = moment(date, 'YYYY-MM-DD');
    //                                         const end = moment(new Date(), 'YYYY-MM-DD');
    //                                         const diff = end.diff(start, 'days');
    //                                         if(diff > 30){
    //                                             userService.setDashboardVisible("false")
    //                                             props.updateHeaderCallback("false")
    //                                         }
    //                                     }
    //                                     }
    //                                 }
    //                             })
    //                         }catch(e){

    //                         }
                                
    //                     }
    //                     else {
    //                         toast('Your card was declined. Please try again',{type:"error"});
    //                     }
    //                 }
    //             }
    //         })
    //         }
    //     }catch(error){
    //         //Erorr
    //     }
    // }

    // const handlePriceSelection = (price,product) => {

    //     if(!currentPaymentMethod || currentPaymentMethod.data?.length < 1 || !currentPaymentMethod.data[0].id){
    //         toast('Please add a payment method.',{type:"error"});
    //         return;
    //     }
      
    //     setSelectedPlan(product)
    //     setSelectedPrice(price)
    //     makePayment(price,product)
    // }

    // const deleteSubscription = () => {
    //     dispatch({
    //         type: authActions.deleteSubscription.type,
    //         payload: {
    //             subscriptionId:userPlanInfo.subscriptionId,
    //             successCallback: (data) => {
    //                 // setPayDisabled(true)
    //                 setSelectedPlan()
    //                 setSelectedPrice()
    //                 setDefaultPayInfo()
    //                 checkSubscription(user)
    //             }
    //         }
    //     })
    // }

    // const getCardView = (mode="pay") => {
    //     return (
    //         <div className="w-full px-5 py-10">
    //         {/* <div className="flex flex-wrap mt-0 w-full items-center py-3">
    //             <div className="w-full md:w-1/6 lg:w-1/6">Email:</div>
    //             <Input
    //                 className="w-full md:w-5/6 lg:w-5/6"
    //                 value={(userUpdate && userUpdate.email) ? userUpdate["email"] : ''}
    //                 onChange={(value, e) => {
    //                     onUpdateValue("email", value)
    //                 }} 
    //             />
    //         </div> */}
    //         <div className="flex flex-wrap mt-0 w-full items-center pt-3">
    //         <div className="w-full md:w-1/6 lg:w-1/6">Card:</div>
    //         <div className="w-full md:w-5/6 lg:w-5/6" style={{border:'1px solid lightgrey',borderRadius:'5px'}}>
    //             <form style={{width:'100%',margin:'10px 2px'}}>
    //             <CardElement onChange={(e)=>{
    //                 if(e.complete){
    //                     setCardInfo(true)
    //                 }else{
    //                     setCardInfo(false)
    //                 }
    //             }}/>
    //         </form>
    //         </div>
    //         </div>
    //         <div className="flex flex-wrap mt-0 w-full items-center py-3">
    //             <div className="w-full md:w-1/6 lg:w-1/6">Name on card:</div>
    //             <Input
    //                 className="w-full md:w-5/6 lg:w-5/6"
    //                 onChange={(value, e) => {
    //                     onUpdateValue("name", value)
    //                 }} 
    //             />
    //         </div>
    //         {/* <div className="flex flex-wrap mt-0 w-full items-center py-3">
    //             <div className="w-full md:w-1/6 lg:w-1/6">Country or Origin:</div>
    //             <Input
    //                 className="w-full md:w-5/6 lg:w-5/6"
    //                 onChange={(value, e) => {
    //                     onUpdateValue("country", value)
    //                 }} 
    //             />
    //         </div> */}
    //         <button onClick={(e) => {
    //             if(mode === 'update'){
    //                 onnUpdatePaymentMethod(e)
    //             }//else makePayment(e)
    //         } }
    //         disabled={payDisabled}
    //             className={`mt-3 justify-center flex font-bold px-8 py-2 rounded-md text-sm text-white border-2 border-solid border-green-200 bg-green-200 w-full`}>
    //                {(!currentPaymentMethod || currentPaymentMethod.data?.length < 1 || !currentPaymentMethod.data[0].id) ? 'Add Card Details' : 'Change Card Details'} 
    //         </button>
    //     </div>
    //     )
    // }

    return(
        <div className="mt-10">
            {/* <h1 className="text-base font-bold">Billing</h1> */}
           
            <div className="w-full flex flex-wrap mt-5">
                {
                    subscriptionDetails && subscriptionDetails.subscriptionDetails && (
                        <>
                        {
                            (subscriptionDetails?.subscriptionDetails.status === 'active' && subscriptionDetails.paymentStatus === "Completed" && !subscriptionDetails?.subscriptionDetails.cancel_at_period_end && (moment.unix(subscriptionDetails?.subscriptionDetails.current_period_end) >= moment(new Date()))) && (
                                <div>{`Your plan will renew on ${moment.unix(subscriptionDetails?.subscriptionDetails.current_period_end).format('MMM DD, YYYY')}. You can cancel your subscription anytime.`}<a onClick={() => window.open(`${process.env.REACT_APP_MANAGE_BILLING_LINK}`, '_blank')} className='text-orange-200 cursor-pointer font-bold'>{` Manage Your Billing`}</a></div>
                            )
                        }
                        {
                            ( subscriptionDetails.subscriptionDetails.cancel_at_period_end && (moment.unix(subscriptionDetails.subscriptionDetails.current_period_end) >= moment(new Date()))) && (
                                <div>{`Your plan will expire on ${moment.unix(subscriptionDetails.subscriptionDetails.cancel_at).format('MMM DD, YYYY')}.`}<a onClick={() => window.open(`${process.env.REACT_APP_MANAGE_BILLING_LINK}`, '_blank')} className='text-orange-200 cursor-pointer font-bold'>{` Manage Your Billing`}</a></div>
                            )
                        }
                        {
                            ( moment.unix(subscriptionDetails.subscriptionDetails.current_period_end) < moment(new Date()) || subscriptionDetails.subscriptionDetails.status !== 'active') && (
                                <div>{`Your plan expired on ${moment.unix( (subscriptionDetails?.subscriptionDetails.status === 'canceled') ? subscriptionDetails?.subscriptionDetails.canceled_at : subscriptionDetails?.subscriptionDetails.current_period_end).format('MMM DD, YYYY')}.`}<a onClick={() => window.open(`${process.env.REACT_APP_MANAGE_BILLING_LINK}`, '_blank')} className='text-orange-200 cursor-pointer font-bold'>{` Manage Your Billing`}</a></div>
                            )
                        }
                        </>
                    )
                }
              
                {/* <div className="h-full w-full mt-5">
                    <div className="w-full flex px-10 gap-x-4 px-0 md:pt-4 lg:pt-4 h-full" style={{alignItems:'center',justifyContent:'center'}}>
                    {
                        activePlans && activePlans.map((item)=>(
                            <div className='w-1/2 px-0'>
                                <div className={`cursor-pointer w-full grid border border-solid rounded-lg px-0 pt-1 p-3 font-bold justify-center plan-card ${selectedPlan && selectedPlan.id === item.id  ? 'selected-plan' : ''}`}>
                                    <div className="mb-4"><h4 className='text-orange-200 text-center'>{item.name}</h4></div>
                                    {
                                        !userPlanInfo.subscriptionDetails || userPlanInfo?.subscriptionDetails?.plan?.product != item.id || (moment.unix(userPlanInfo?.subscriptionDetails.current_period_end) < moment(new Date())) || userPlanInfo?.subscriptionDetails.cancel_at_period_end || userPlanInfo?.subscriptionDetails.status !== 'active' ?
                                        item.price.map((i)=>(
                                        <button className={`px-3 card-button p-0 mb-2 ${selectedPrice && selectedPrice.id === i.id  ? 'selected-price' : ''}`} style={{fontSize:'12px'}} onClick={()=>handlePriceSelection(i,item)}>${i.unit_amount ? Number((i.unit_amount / 100).toFixed(2)) : 0} <span style={{fontSize:'12px'}}>/{i.recurring.interval}</span></button>
                                    )):
                                    (
                                        <>
                                        {
                                            ((moment.unix(userPlanInfo?.subscriptionDetails.current_period_end) >= moment(new Date()))) && userPlanInfo?.subscriptionDetails?.plan?.product === item.id && (
                                            <>
                                            <div className={` p-0 mb-2`} style={{fontSize:'14px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'default'}} >{`$${ Number((userPlanInfo?.subscriptionDetails?.plan?.amount / 100).toFixed(2))} /${userPlanInfo?.subscriptionDetails?.plan?.interval}`}</div>
                                            <button onClick={() => setShowConfirmationModal(true)} className={`card-button p-0 mb-2 px-3`} style={{fontSize:'12px'}}>Unsubscribe</button>
                                            </>
                                        )
                                        }
                                        </>
                                    )
                                    }
                                </div>
                            </div>
                        ))
                      
                    }
                    </div>              
                </div> */}
            </div>
            {/* <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"/>        */}
            {/* <div>
                <div className='justify-between flex items-center'>
                    <h1 className="text-base font-normal px-2">Payment Method</h1>
                    {
                        (!currentPaymentMethod || currentPaymentMethod.data?.length < 1 || !currentPaymentMethod.data[0].id)
                        ? <button onClick={() => setShowChangeCard(!showChangeCard)} className={`card-button text-white bg-orange-200 w-40 text-bold`} style={{fontSize:'14px'}}>{showChangeCard ? 'Cancel' :'Add Card'}</button>
                        : <button onClick={() => setShowChangeCard(!showChangeCard)} className={`card-button text-white bg-orange-200 w-40 text-bold`} style={{fontSize:'14px'}}>{showChangeCard ? 'Cancel' :'Change Card'}</button>
                    }
                </div>
                {
                    showChangeCard && (
                        getCardView('update')
                    )
                }
            </div> */}
            {/* <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"/> */}
            {/* <div className='w-80'>
                {
                    currentPaymentMethod && currentPaymentMethod.data?.length > 0 && (

                        <div className='shadow-lg p-4 mt-5 border border-solid rounded'>
                            <div className='p-1 text-base' >{`Card Network: ${currentPaymentMethod.data[0].card.brand}`}</div>
                            <div className='p-1 text-base'>{`Card Type: ${currentPaymentMethod.data[0].card.funding}`}</div>
                            <div className='p-1 text-base'>{`Expiry: ${currentPaymentMethod.data[0].card.exp_month}/${currentPaymentMethod.data[0].card.exp_year}`}</div>
                            <div className='p-1 text-base'>{`Card No: **** **** **** ${currentPaymentMethod.data[0].card.last4}`}</div>
                        </div>
                    )
                }
            </div> */}

            {/* <ConfirmationModel
                isVisible={showConfirmationModal}
                title=""
                message = {"Are you sure you want to cancel your subscription?"}
                onSave={deleteSubscription}
                onClose={() => setShowConfirmationModal(false)}
                cancelName="No"
                continueName="Yes"
                // defaultAvailableColumns = {defaultAvailableColumns}
               
            /> */}
        </div>
     
    )
}

export default Billing;