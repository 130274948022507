import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
// import Toast from '../../../services/toastService';
import { useDispatch } from "react-redux";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { useGoogleLogin } from '@react-oauth/google';
import  Header  from "../../components/Header";
import * as Yup from "yup";
import axios from 'axios';
import { browserName } from 'react-device-detect';
import ConfirmationModel from "../../components/ConfirmationModel";
import htmls from "../../components/agreementInfo/index"

export const SignUp = () => {

    const SignupSchema = Yup.object().shape({
        first_name: Yup.string().required("First name is requred."),
        last_name: Yup.string().required("Last name is requred."),
        email: Yup.string().email("Invalid email").required("Email Required"),
        // npn: Yup.string().required("NPN is requred."),
        password: Yup.string()
          .required("No password provided.")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain one uppercase, one number and one special case character."),
      });

      const NpnSchema = Yup.object().shape({
        npn: Yup.string().required("NPN is requred.")
      });

    const dispatch = useDispatch();
    // const [user, setUser] = useState({email:'', password:''});
    const [showPassword, setShowPassword] = useState(false)
    const [userData, setUserData] = useState()
    const [showVerifyNpm, setShowVerifyNmp] = useState(false);
    const [publicIP, setPublicIP] = useState('')
    const [openAgreementModal,setOpenAgreementModal] = useState(false)
    const [acceptAgreement,setAcceptAgreement] = useState(false)

    const getIP = async() => {
        try{
            const res = await axios.get(`https://api.ipify.org/?format=json`);
            setPublicIP(res.data.ip);
        }catch(err){
            // console.log("err ==", err)
        }
    }

    useEffect(() => {
        getIP();
    }, [])

    const saveData = (values) => {
        setUserData({...values, phone:`${values.phone}`});
        setShowVerifyNmp(true);
        // dispatch({
        //     type: authActions.registerUser.type,
        //     payload: {
        //         data: {
        //             ...values,
        //             'phone':`${values.phone}`
        //         }
        //     }
        // })
    }

    const registerUser = (values) => {
        dispatch({
            type: authActions.registerUser.type,
            payload: {
                data: values
            }
        })
    }

    const verifyNpm = (values) => {

        dispatch({
            type: authActions.validateNpn.type,
            payload: {
                data: {
                    npn: values.npn,
                    last_name: userData.last_name
                },
                successCallback: () => {
                    let user = {
                        ...userData,
                        npn: values.npn,
                        tosLog: {
                            browserName: browserName,
                            publicIP: publicIP,
                            userAgent: window.navigator.userAgent
                        }
                    }
                    // console.log(user)
                    delete user.toc
                    setUserData(user);
                    registerUser(user);
                }
            }
        })
    }

    const handleGoogleSuccess = async (access_token) => {

        dispatch({
            type: authActions.loginGoogleUser.type,
            payload: {
                data: {
                    token:access_token
                }
            }
        })
        // const { authType } = ref?.current?.values;
        // const user: IUser = await loginWithGoogle(data.code, authType);
        // dispatch(setUser(user));
       
        // router.replace(`/account/${user.defaultAuthType ? user.defaultAuthType : 'influencer'}`);
        // router.push(`/account/${authType}`);
      };

    const loginG = useGoogleLogin({
        onSuccess: (tokenResponse) => {
        //   console.log("tokenResponse =", tokenResponse);
          handleGoogleSuccess(tokenResponse.code)
        },
        flow: 'auth-code',
      });

    const toggleCheckbox = (e) => {
        if(acceptAgreement){
            setAcceptAgreement(false)
        }
    }

    return(
        <div className="font-Poppins pb-10 bg-green-100 w-full h-screen overflow-y-scroll overflow-x-hidden">
             <Header/>
            <div className="flex flex-wrap mt-0 w-full bg-green-100 h-full ">
                {
                    !showVerifyNpm ? (
                        <div className="w-full md:w-1/2 lg:w-1/2 z-[100] mt-10 p-4 pb-16 md:pl-16 lg:pl-16 md:p-0 lg:p-0">
                        <Formik
                            validationSchema={SignupSchema}
                            initialValues = {{email:'', password:''}}
                            onSubmit={(values) => {
                            saveData(values);
                        }}
                        >
                        {
                            ({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleChange,
                                setValues,
                            }) => (
                                <Form>
                                    <div className="bg-white rounded-2xl p-5 w-full px-10 py-10">
                                        <h1 className="font-bold text-xl text-gray-600">Sign Up</h1>
                                        <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                                        <p className="py-3 text-gray-500 text-lg">Sign Up for your MediZues account.</p>
        
                                        
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-2">
                                                <p className="text-gray-500 text-md font-bold flex w-full justify-center">First Name <p className="text-red-500">*</p></p>
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type="text"
                                                    name="first_name"
                                                    value={values?.first_name}
                                                    required
                                                    placeholder=""
                                                />
                                                 {errors.first_name && touched.first_name ? (
                                                    <p className="mt-2 text-red-500 text-xs">{errors.first_name}</p>
                                                ) : null}
                                            </div>
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-2">
                                                <p className="text-gray-500 text-md font-bold flex w-full justify-center">Last Name  <p className="text-red-500">*</p></p>
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type="text"
                                                    name="last_name"
                                                    value={values?.last_name}
                                                    required
                                                    placeholder=""
                                                />
                                                  {errors.last_name && touched.last_name ? (
                                                    <p className="mt-2 text-red-500 text-xs">{errors.last_name}</p>
                                                ) : null}
                                            </div>
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-2">
                                                <p className="text-gray-500 text-md font-bold flex w-full justify-center">Email<p className="text-red-500">*</p></p>
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type="email"
                                                    name="email"
                                                    value={values?.email}
                                                    required
                                                    placeholder=""
                                                />
                                                 {errors.email && touched.email ? (
                                                    <p className="mt-2 text-red-500 text-xs">{errors.email}</p>
                                                ) : null}
                                            </div>
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-2 ">
                                                <p className="text-gray-500 text-md font-bold">Phone</p>
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type="number"
                                                    name="phone"
                                                    value={values?.phone}
                                                    placeholder=""
                                                />
                                            </div>
                                            {/* <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-2">
                                                <p className="text-gray-500 text-md font-bold">NPN</p>
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type="text"
                                                    name="npn"
                                                    value={values?.npn}
                                                    placeholder=""
                                                />
                                            </div> */}
                                            <div className="mt-2 rounded-2xl border-2 border-green-100 p-3 text-center my-2 ">
                                                <p className="text-gray-500 text-md font-bold flex w-full justify-center">Password<p className="text-red-500">*</p></p>
                                                <div className="flex items-center ">
                                                <Field
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    value={values?.password}
                                                    required
                                                    placeholder=""
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                />
                                                    <img alt="" onClick={() => setShowPassword(!showPassword)} className="cursor-pointer h-6 -ml-10 mt-1" src="/images/eye_grey.png"  />
                                                </div>
                                                {errors.password && touched.password ? (
                                                    <p className="mt-2 text-red-500 text-xs">{errors.password}</p>
                                                ) : null}
                                            </div>
                                            <div className="flex mt-3 gap-x-2 items-start">
                                                <Field className="mt-1" required type="checkbox" name="toc" checked={acceptAgreement} onClick={(e)=>toggleCheckbox(e)}/>
                                                <p className="text-gray-400 ">
                                                I acknowledge that I have received and read the Fair Credit Reporting Act Disclosure in the Agent's <a onClick={() => {
                                                    window.open('https://medizues.com/terms-of-service/', '_blank')
                                                }} className="font-bold text-orange-200 cursor-pointer"> Terms and Conditions</a> of Service, and I hereby authorize MediZues or its authorized agents to obtain or prepare consumer reports at any time after it receives this authorization to verify licensing and credentials via my National Producer Number. I certify that I understand the documents I have received. I also agree to MediZues's <a onClick={() => {
                                                    window.open('https://medizues.com/privacy-policy/', '_blank')
                                                }} className="font-bold text-orange-200 cursor-pointer">Privacy Policy.</a>
                                                <p className="text-gray-400 " style={{marginTop:'10px'}}>I agree to MediZues's<a onClick={() => {
                                                    setOpenAgreementModal(true)
                                                }} className="font-bold text-orange-200 cursor-pointer"> Business Associate Agreement. (click & scroll)</a>
                                                </p>
                                                </p>

                                                {/* <p className="text-gray-400 ">Agree to <a onClick={() => {
                                                    window.open('https://medizues.com/terms-of-service/', '_blank')
                                                }} className="font-bold text-orange-200 cursor-pointer">Terms of Service</a> and <a onClick={() => {
                                                    window.open('https://medizues.com/privacy-policy/', '_blank')
                                                }} className="font-bold text-orange-200 cursor-pointer"> Privacy Policy</a></p> */}
                                            </div>

                                            <div className=" text-gray-400 flex flex-wrap gap-x-5 items-center mt-10 w-full justify-between">
                                            <p className="flex gap-x-1">Already have an account? <a  href="/login" className="font-bold text-orange-200">Login</a></p>
                                            <button type="submit" className="font-bold text-white bg-green-200 py-1 px-8 rounded-md mt-5 md:mt-0 lg:mt-0">{`Sign Up >`}</button>
                                        </div>
                                        {/* <div onClick={() => loginG()} className="flex items-center text-gray-400 font-bold border border-gray-50 bg-white shadow-lg w-full px-3 py-1 mt-5 rounded-md ">
                                            <img alt="" className="h-10" src="/images/google-button.png"  /> <div className="flex w-full justify-center"><p>Or continue with Google</p></div>
                                        </div> */}
                                    </div>
                                </Form>
                            )
                        }
                        </Formik>
                       
                        </div>
                    ):
                    (
                        <div className="w-full md:w-1/2 lg:w-1/2 z-[100] mt-48 p-4  md:pl-16 lg:pl-16 md:p-0 lg:p-0">
                        <Formik
                            validationSchema={NpnSchema}
                            initialValues = {{npn:''}}
                            onSubmit={(values) => {
                            verifyNpm(values);
                        }}
                        >
                        {
                            ({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleChange,
                                setValues,
                            }) => (
                                <Form>
                                    <div className="bg-white rounded-2xl p-5 w-full px-10 py-10">
                                        <h1 className="font-bold text-xl text-gray-600">Verify NPN</h1>
                                        <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                                        {/* <p className="py-3 text-gray-500 text-lg">Sign Up to your MediZues account.</p> */}
        
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-2">
                                                <p className="text-gray-500 text-md font-bold">NPN</p>
                                                <Field
                                                    required
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type="text"
                                                    name="npn"
                                                    value={values?.npn}
                                                    placeholder=""
                                                />
                                            </div>
                                           
                                            <div className=" text-gray-400 flex flex-wrap gap-x-5 items-center mt-10 w-full justify-between">
                                            <button type="button" onClick={() => setShowVerifyNmp(false)} className="font-bold text-white bg-orange-200 py-1 px-8 rounded-md mt-5 md:mt-0 lg:mt-0">{`< Back`}</button>
                                            <button type="submit" className="font-bold text-white bg-green-200 py-1 px-8 rounded-md mt-5 md:mt-0 lg:mt-0">{`Verify >`}</button>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                        </Formik>
                       
                        </div>
                    )
                }
               
                <div className="hidden md:block lg:block w-full md:w-1/2 lg:w-1/2 mt-10">
                    {/* <img src="/images/splash.png"  /> */}
                    <div className="z-0 w-full flex justify-between relative">
                        <img alt="" src="/images/cloud_1.png" className="z-0 w-2/6 h-42 object-contain absolute pt-52" />
                        <img alt="" src="/images/zues.png"  className="w-4/6 h-80 object-contain pt-20"/>
                        <img alt="" src="/images/cloud_2.png" className="z-0 w-2/6 h-42 object-contain pt-96" />
                    </div>
                    <div className="lg:-mt-52 md:-mt-44 z-[100] relative">
                        <div className="w-full flex justify-center">
                            <img alt="" src="/images/medizues-text.png" className="h-24 w-60 object-contain item-center" />
                        </div>
                        {/* <h1 className=" text-center text-orange-200 font-bold text-4xl">MediZues</h1> */}
                        <h1 className="text-center text-green-200 font-bold text-lg mt-2">Get God-like Insights on Your Clients' Behavior</h1>
                        {/* <h1 className=" text-center text-orange-200 font-bold text-4xl">The mighty<br/> ZuesRx extension</h1> */}
                        {/* <h1 className="text-center text-green-200 font-bold text-lg mt-2">simply makes the most of your sheets!</h1> */}
                    </div>
               
                </div>
                <ConfirmationModel
                isVisible={openAgreementModal}
                title="Terms & Conditions"
                message = {<div dangerouslySetInnerHTML={{ __html: htmls }}/>}
                continueName="I Agree"
                onSave={() => setAcceptAgreement(true)}
                onClose={() => setOpenAgreementModal(false)}
               
            />
            </div>
        </div>
    )
}