import { useEffect, useState } from "react";
import { Modal, Table, InputPicker } from "rsuite";
import { Uploader } from 'rsuite';
import { toast } from 'react-toastify';
import Papa from 'papaparse';

const { Column, HeaderCell, Cell } = Table;

const UploadFileModal = (props) => {
    const [fileList, setFileList] = useState([]);
    const [fileList1, setFileList1] = useState([]);
    const [errorSizeMessage, setErrorSizeMessage] = useState("");
    const [tab,setTab] = useState(1)
    const [mbiPreviewData,setMbiPreviewData] = useState("")
    const [dobPreviewData,setDobPreviewData] = useState("")
    const [data,setData] = useState([
        {medizuesField:'MBI',previewInfo:''},
        {medizuesField:'Date of Birth',previewInfo:''}
    ]) 
    const [columns,setColumns] = useState([])
    const [previewData,setPreviewData] = useState([])
    const dobFormats = [
        {label:'MM-DD-YYYY',value:'MM-DD-YYYY'},
        {label:'DD-MM-YYYY',value:'DD-MM-YYYY'},
        {label:'YYYY-MM-DD',value:'YYYY-MM-DD'},
        {label:'MM/DD/YYYY',value:'MM/DD/YYYY'},
        {label:'DD/MM/YYYY',value:'DD/MM/YYYY'},
        {label:'YYYY/MM/DD',value:'YYYY/MM/DD'},
        {label:'MM.DD.YYYY',value:'MM.DD.YYYY'},
        {label:'DD.MM.YYYY',value:'DD.MM.YYYY'},
        {label:'YYYY.MM.DD',value:'YYYY.MM.DD'}
       
    ]

    const mbiHeaderKeys = [
    "medicarebeneficiaryindicator",
    "mbis",
    "medicareno",
    "medicarebeneficiaryidentifier",
    "medicarenumber",
    "medicare#",
    "mbi",
    "medicareid",
    "medicareidnumber",
    "medicareid#",
    "medicarebeneficiaryid"]

    const dobHeaderKeys = [
    "birthdate",
    "dateofbirth",
    "dob",
    "birthday",
    "dateofbirths",
    ]

    const onSubmit = (val) => {
        props.onSave(fileList.length > 0 ? fileList : fileList1, columns.find(x => x.value === props.mbiHeader)?.label, columns.find(x => x.value === props.dobHeader)?.label);
    }

    function removeSpacesAndDashes(inputString) {
        return inputString.toLowerCase().replace(/[\s-]/g, '');
    }

    function getObjectByProperty(arrayOfObjects, propertyName, searchStringArray) {
        return arrayOfObjects.find(obj => searchStringArray.includes(obj[propertyName].toLowerCase()));
    }

    const onSave = (val) => {
        // const file
        if(fileList.length <1 && fileList1.length < 1){
            toast('Please select file to  upload!',{type:"error"});
            return;
        }
        Papa.parse(fileList1[0]?.blobFile,{
            header:true,
            skipEmptyLines:true,
            complete: function(result) {
                // let columnArray = []
                let valueArray = []
                // console.log("file res =", result.data)

                result.data.map((d)=>{
                    // columnArray.push(Object.keys(d));
                    valueArray.push(Object.values(d));
                })
                let tempData = result.data.length > 0 ? Object.keys(result.data[0])?.map(item => ({ label: item, value: removeSpacesAndDashes(item) })) : [];
                
                setColumns(tempData)
                // console.log("columns Arr", tempData)
                setPreviewData(valueArray)
                // console.log("valueArray== ",valueArray)
            }
        })
        // console.log(fileList,fileList1)
        setErrorSizeMessage("")
        // onCancel()
        switchTab(val)
    }

    useEffect(() => {
        if(!props.mbiHeader || !props.dobHeader){
        let tempMbiHeader = getObjectByProperty(columns, 'value', mbiHeaderKeys)
        let tempDobHeader = getObjectByProperty(columns, 'value', dobHeaderKeys)
        // console.log("tempMbiHeader ", tempMbiHeader, "tempDobHeader =", tempDobHeader)
        props.setMbiHeader(tempMbiHeader?.value)
        let index1 = columns.findIndex(obj => obj.value === tempMbiHeader?.value);
        const mbival = previewData.find(x => x[index1])
        setMbiPreviewData(mbival ? mbival[index1]: '')
       
        props.setDobHeader(tempDobHeader?.value)
        let index2 = columns.findIndex(obj => obj.value === tempDobHeader?.value);
        const val = previewData.find(x => x[index2])
        setDobPreviewData(val ? val[index2]: '')
        }
    }, [columns])
    

    const onCancel = () => {
        switchTab(1)
        setFileList([])
        setFileList1([])
        props.onClose()
    }

    const switchTab = (val) => {
        setTab(val)
    }

    const renderBadge = (val) => {
        return (
            <div className={`border-2 border-solid ${val == tab ? 'border-green-200 text-green-200' : val < tab ? 'border-green-200 bg-green-200 text-white' : 'border-gray-200 text-gray-300'} py-2 px-4 mr-3 rounded-full font-Poppins font-bold`}>{val}</div>
        )
    }

    const handleDobHeader = (e) => {
        props.setDobHeader(e)
        let index = columns.findIndex(obj => obj.value === e);
        const dobval = previewData.find(x => x[index])
        setDobPreviewData(dobval ? dobval[index]: '')
       
    }

    const handleMbiHeader = (e) => {
        props.setMbiHeader(e)
        let index = columns.findIndex(obj => obj.value === e);
        const mbival = previewData.find(x => x[index])
        setMbiPreviewData(mbival ? mbival[index]: '')
       
    }

    const renderTab = () => {
        switch (tab) {
            case 1:
                return (
                    <div className="font-Poppins">
                    <Uploader 
                        action="//jsonplaceholder.typicode.com/posts/" 
                        draggable 
                        autoUpload={false} 
                        multiple={false} 
                        fileList={fileList}
                        
                        accept=".csv"
                        onChange={(files) => {
                            // console.log("drag files ==", files);
                           setErrorSizeMessage('')
                            if(files.length > 0 && files[files.length-1].blobFile.size > 2000000){
                                setErrorSizeMessage("File size should not be more than 2MB")
                                setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                                setFileList([])
                            }else{
                            setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                            setFileList([])
                            }
                        }
                        }>
                            <div style={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: "green", borderRadius:5 }}>
                                <span className="text-xl text-green-200 font-extrabold text-center">Drag &amp; Drop your .csv file <br/> anywhere in this box</span>
                            </div>
                    </Uploader>

                    <Uploader
                        className="w-full pl-3 mt-5 gap-x-1 font-medium cursor-pointer text-green-200 text-base"
                        autoUpload={false} 
                        fileList={fileList1}
                        accept=".csv"
                        multiple={false} 
                        // action="//jsonplaceholder.typicode.com/posts/"
                        onChange={(files) => {
                            // console.log("drag files ==", files);
                            setErrorSizeMessage('')
                            if(files.length > 0 && files[files.length-1].blobFile.size > 2000000){
                                setErrorSizeMessage("File size should not be more than 2MB")
                                setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                                setFileList([])
                            }else{
                            setFileList1(files.length > 0 ? [files[files.length-1]] : [])
                            setFileList([])
                            }
                           
                        }
                        }
                    >
                        <div>
                        <div className="underline underline-green-200 underline-offset-4">Or select file here</div>
                        {!errorSizeMessage && <div className="text-xs mt-5 text-gray-400">{"NOTE: File size should not exceed 2MB."}</div>}
                        <div className="text-red-500 mt-5">{errorSizeMessage}</div>
                        </div>
                        
                    </Uploader>
                    
                    <div className="flex justify-between gap-x-3 mt-10">
                        <div onClick={onCancel} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                            {`< Cancel`}
                        </div>
                        <div onClick={() => {
                            if(!errorSizeMessage)
                                onSave(2)
                            }} className={`${errorSizeMessage.length > 0 ? 'cursor-not-allowed' : 'cursor-pointer'}  flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg`}>
                           {`Next >`}
                        </div>
                    </div>
                    
            </div>
                )
                break;
            case 2:
                return(
                    <div className="font-Poppins">
                    <div className="flex w-full justify-center my-4">
                    <span className="font-semibold text-xs">Map the columns in your file to the required MediZues data.</span>
                    </div>
                    <div>
                        <Table virtualized data={data}>
      <Column flexGrow={1} resizable>
        <HeaderCell>MediZues data</HeaderCell>
        <Cell dataKey="medizuesField" />
      </Column>

      <Column flexGrow={1} resizable>
        <HeaderCell>Preview info</HeaderCell>
        <Cell dataKey="previewInfo">
                {(rowData, index) => {
            return (
                index == 0 && props.mbiHeader ? mbiPreviewData : index == 1 && props.dobHeader ? dobPreviewData : ''
            )
        }}
        </Cell>
      </Column>

      <Column flexGrow={2}>
        <HeaderCell>Import file column header</HeaderCell>
        <Cell dataKey="">
        {(rowData, index) => {
            return (
                rowData?.medizuesField === "Date of Birth" ?
                <div className="w-full flex gap-x-2">
                <InputPicker className="-mt-2 mr-1 w-1/2" data={columns} value={props.dobHeader} onChange={(e)=>handleDobHeader(e)} onClean={()=>props.setDobHeader('')}  />
                <InputPicker className="-mt-2 w-1/2" placeholder="Select format" onChange={(e)=>props.setSelectedDateFormat(e)} value={props.selectedDateFormat} data={dobFormats}  />
                </div>
                :<InputPicker className="-mt-2 w-full" data={columns} value={props.mbiHeader} onChange={(e)=>handleMbiHeader(e)} onClean={()=>props.setMbiHeader('')} />

            )
        }}
        </Cell>
      </Column>
    </Table>
                    </div>
                    <div className="flex justify-between gap-x-3 mt-10">
                        <div onClick={()=>{
                            switchTab(1)
                        }} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                            {`< Back`}
                        </div>
                        <div onClick={() => {
                            // console.log("props.mbiHeader=", props.mbiHeader, "props.dobHeader =", props.dobHeader, "props.selectedDateFormat =", props.selectedDateFormat)
                            if(!props.mbiHeader || !props.dobHeader || !props.selectedDateFormat){
                                toast('Add all information first!',{type:"error"}); return;
                            }else switchTab(3)
                            }} className={`${(!props.mbiHeader || !props.dobHeader || !props.selectedDateFormat) ? 'cursor-not-allowed' : 'cursor-pointer'} flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg`}>
                           {`Next >`}
                        </div>
                    </div>
                    </div>
                )
                break;
            case 3:
                return(
                    <div className="font-Poppins">
                    <div className="flex w-full justify-center my-4">
                    <span className="font-semibold text-xs text-gray-400">A few final details.</span>
                    </div>
                    <div className="font-semibold text-xs text-gray-600">
                    Select destination folder: <InputPicker className="ml-3" onChange={(e) => props.setSelectedFolderForUpload(e)} onClean={()=>props.setSelectedFolderForUpload('')} labelKey="label" valueKey="id" data={props?.folders} defaultValue={props?.selectedFolder?.id} style={{ width: 224 }} />
                    </div>
                    <div className="flex justify-between gap-x-3 mt-10">
                        <div onClick={()=>{
                            switchTab(2)
                        }} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                            {`< Back`}
                        </div>
                        <div onClick={() => {
                                if(!props.selectedFolderForUpload){
                                    toast('Select Folder',{type:"error"}); return;
                                }else{
                                    onSubmit()
                                    onCancel()
                                }
                               
                            }} className={`${!props.selectedFolderForUpload ? 'cursor-not-allowed' : 'cursor-pointer '} flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg`}>
                           {`Submit >`}
                        </div>
                    </div>
                    </div>
                )
                break;
            default:
                break;
        }
    }

    return (
        <Modal open={props.isVisible} className="w-3/5" onClose={onCancel}>
            <Modal.Header>
                <Modal.Title className="font-Poppins m-5 my-0 ml-0 font-bold text-xl">{}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow:'hidden'}}>
            <div className="mb-2">
            <div className="mb-3 w-full flex rounded-lg border border-solid  divide-x">
                <div className="flex-1 flex items-center font-bold p-3">{renderBadge(1)} Upload</div>
                <div className="flex-1 flex items-center font-bold p-3">{renderBadge(2)} Map</div>
                <div className="flex-1 flex items-center font-bold p-3">{renderBadge(3)} Details</div>
            </div>
            {renderTab()}
            </div>    
            </Modal.Body>
        </Modal>
    );
};

UploadFileModal.defaultProps = {
    title: "Add Column",
    show: false,
    onHide: () => { },
    onSubmit: () => { },
};

export default UploadFileModal;
