import React, {useState, useEffect} from 'react';
import {  Input, Toggle} from 'rsuite';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import './../../../custom'

const Preferences = (props) => {
    
    const dispatch = useDispatch();
    const location = useLocation();

    const [userUpdate, setUserUpdate] = useState(props.user);

    const onUpdateValue = (key, value) => {
        setUserUpdate({
            ...userUpdate,
            [key]: value
        })
    }

    return(
        <div className="mt-10">
            <h1 className="text-base font-bold">Notifications</h1>

            <div className="w-full flex fllex-wrap mt-5">
                <div className="">
                    <div className="flex flex-wrap mt-0 w-full items-center py-2 gap-x-3">
                        <Toggle 
                        // checked={}
                        onChange={(checked, e) => {

                        }}
                        defaultChecked />
                        <div>When a client authentication is successfull</div>
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-2 gap-x-3">
                        <Toggle 
                        // checked={}
                        onChange={(checked, e) => {

                        }}
                        defaultChecked />
                        <div>When a client authentication fails</div>
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-2 gap-x-3">
                        <Toggle 
                        // checked={}
                        onChange={(checked, e) => {

                        }}
                        defaultChecked />
                        <div>When a new report is ready based on preferences</div>
                    </div>
                </div>
            </div>
        
            <h1 className="text-base font-bold mt-6">Automatically run:</h1>
            <div className="flex flex-wrap mt-0 gap-x-10 mt-5">
                <div className="flex items-center py-2 gap-x-3">
                    <Toggle 
                    // checked={}
                    className="toggle-green-100"
                    onChange={(checked, e) => {

                    }}
                    defaultChecked />
                    <div>Daily</div>
                </div>
                <div className="flex items-center py-2 gap-x-3">
                    <Toggle 
                    // checked={}
                    onChange={(checked, e) => {

                    }}
                    defaultChecked />
                    <div>Weekly</div>
                </div>
            </div>
        </div>
     
    )
}

export default Preferences;