import React, { useState } from 'react';
import copy from 'clipboard-copy';
import CopyIcon from "@rsuite/icons/Copy";
import { toast } from 'react-toastify';

const CopyToClipboardButton = ({ textToCopy = "" }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
      toast.success("Copied!")
    } catch (error) {
      console.error('Error copying to clipboard', error);
    }

    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div>
      <button onClick={(e) => {
        e.stopPropagation()
        handleCopyClick()
        }}>
        {/* {isCopied ? 'Copied!' : 'Copy'} */}
        <CopyIcon style={{ fontSize: 14, marginRight: 0 }}/>
      </button>
    </div>
  );
};

export default CopyToClipboardButton;
