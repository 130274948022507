import React, {  useState,useEffect } from "react";

import { useDispatch } from "react-redux";
import  Header  from "../../components/Header";
import { redirect } from '../../../utils/response';
import userService from '../../../services/userService';
import { CardElement,useElements,useStripe } from '@stripe/react-stripe-js';
import { Input } from "rsuite";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

export const Payment = () => {
    let { id } = useParams();
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(null);
    const elements = useElements()
    const stripe = useStripe()
    const dispatch = useDispatch()
    const [userUpdate, setUserUpdate] = useState({name:""});
    const [payDisabled,setPayDisabled] = useState(true)
    const [cardInfo,setCardInfo] = useState(false)

    useEffect(() => {
        userService.getUser().then(value => {
            // console.log("value user= ==", value)
            if (value){
                setUser(value);
            }
             
          });
          userService.getSignupUserId().then(value => {
            setUserId(value);
          });
    },[])

    useEffect(() => {
        if(!userUpdate?.name|| !cardInfo){
            setPayDisabled(true)
        }else{
            setPayDisabled(false)
        }
    }, [userUpdate,cardInfo])

    const onUpdateValue = (key, value) => {
        setUserUpdate({
            ...userUpdate,
            [key]: value
        })
    }

    // const updatePaymentMethod = (paymnentId) => {
    //     dispatch({
    //         type: authActions.updatePaymentMethod.type,
    //         payload: {
    //             data: {
    //                 paymentMethodId:paymnentId
    //             },
    //             successCallback: () => {
    //                 // console.log("updatePaymentMethod ==", data)
    //                 // clearCardInfo()
    //                 // setShowChangeCard(false)
    //                 // getPaymentMethod()
    //                 //setActivePlans(data)
    //             }
    //         }
    //     })
    // }


    const makePayment = async (e) => {
        e.preventDefault();
        if(!stripe || !elements){
            return;
        }

        const cardElement = elements.getElement(CardElement)
        const {error,paymentMethod} = await stripe.createPaymentMethod({
            type:"card",
            card:cardElement
        })
        if(!error){
            try{
                // updatePaymentMethod(paymentMethod.id);
                const paymentMethodId = paymentMethod.id
                if(id){
                    dispatch({
                    type: authActions.initiatePayment.type,
                    payload: {
                        data:{
                            "packageId":id,
                            "paymentMethodId":paymentMethodId,
                            "userId":userId
                        },
                        successCallback: (data) => {
                            if (data.latest_invoice.payment_intent && data.latest_invoice.payment_intent.status === 'succeeded') {
                                const checkSubscription = setInterval(() => {
                                    try{
                                        dispatch({
                                            type: authActions.checkSubscription.type,
                                            payload: {
                                            userId:userId,
                                            showToastMsgFor:"signup",
                                            successCallback: (data) => {
                                            if(data?.subscribed && data?.subscriptionDetails.status === 'active'){
                                                clearInterval(checkSubscription);
                                                toast('You are successfully registered. Please verify your email.',{type:"success"});
                                                setTimeout(() => {
                                                    redirect(`/login`)
                                                }, 1000)
                                            }}}
                                        })
                                    }catch(e){

                                    }

                                }, 2000);
                            }else if (data.status === 'active') {
                                try{
                                    dispatch({
                                        type: authActions.checkSubscription.type,
                                        payload: {
                                        userId:userId,
                                        successCallback: () => {
                                            toast('You are successfully registered. Please verify your email.',{type:"success"});
                                            setTimeout(() => {
                                                redirect(`/login`)
                                            }, 1000)
                                    }
                                        }
                                    })
                                }catch(e){

                                }                                   
                            }
                            else {
                                toast('Your card was declined. Please try again',{type:"error"});
                            }
                        }
                    }
                })
                }
            }catch(error){
                //Erorr
            }
        }else{
            console.log(error)
            //Error.message
        }
    }

    return(
        <div className="font-Poppins pb-10 bg-green-100 w-full h-screen overflow-y-scroll overflow-x-hidden">
            <Header/>
            <div className="flex flex-wrap mt-0 w-full bg-green-100 h-full" style={{justifyContent:'center'}}>
                <div className="w-full md:w-1/2 lg:w-1/2 z-[100] mt-10 p-4 pb-16 md:pl-16 lg:pl-16 md:p-0 lg:p-0">
                <div className="bg-white rounded-2xl p-5 w-full px-10 py-10">
                    <h1 className="font-bold text-xl text-gray-600">Card Details</h1>
                    <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                    <p className="py-3 text-gray-500 text-lg">Please enter your payment detail.</p>
                    <div className="w-full flex gap-x-4 px-0 md:px-8 lg:px-8" style={{alignItems:'center',flexDirection:'column'}}>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Name on card:</div>
                        <Input
                            className="w-full md:w-5/6 lg:w-5/6"
                            // value={(userUpdate && userUpdate.npn) ? userUpdate["npn"] : ''}
                            onChange={(value, e) => {
                                onUpdateValue("name", value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                    <div className="w-full md:w-1/6 lg:w-1/6">Card:</div>
                    <div className="w-full md:w-5/6 lg:w-5/6" style={{border:'1px solid lightgrey',borderRadius:'5px'}}>
                    <form style={{width:'100%',margin:'10px 2px'}}>
                        <CardElement onChange={(e)=>{
                            if(e.complete){
                                setCardInfo(true)
                            }else{
                                setCardInfo(false)
                            }
                        }}/>
                    </form>
                    </div>
                    </div>
                    {/* <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Country or Origin:</div>
                        <Input
                            className="w-full md:w-5/6 lg:w-5/6"
                            onChange={(value, e) => {
                                onUpdateValue("country", value)
                            }} 
                        />
                    </div> */}
                    <div className="flex gap-x-3">
                    <button onClick={(e) => {redirect('/plans')}} 
                        className={`mt-3 justify-center flex font-bold px-8 py-2 rounded-md text-sm text-white border-2 border-solid border-gray-500 bg-gray-500`}
                        >
                            Cancel
                    </button>
                    <button onClick={(e) => makePayment(e)} 
                        className={`mt-3 justify-center flex font-bold px-8 py-2 rounded-md text-sm text-white border-2 border-solid border-green-200 bg-green-200`}
                        disabled={payDisabled}
                        >
                            Submit
                    </button>
                    </div>
                   
                    </div>     
                </div>
                </div>
            </div>
            
        </div>
    )
}