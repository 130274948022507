import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { request } from '../../../utils/request';
// import Toast from '../../../services/toastService';
import { toast } from 'react-toastify';
// import { removeTheme, saveTheme } from '../../../services/themeService';
// import { isAdmin } from '../../../services/authService';
// import paths from '../../../config/paths';
import { delayedRedirect, redirect } from '../../../utils/response';
import userService from '../../../services/userService';
import localforage from "localforage";
import FileDownload from 'downloadjs';
import moment from 'moment-timezone';

export function* loginGoogleUser(action) {
  yield put(actions.loginUserLoading());
  try{
    const data = yield call(
      request,
      `auth/google-login`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.loginUserSuccess(data.user));
    toast('You are successfully logged in',{type:"success"});
    yield call(userService.saveUserToken, data.tokens.access, 'access');
    yield call(userService.saveUserToken, data.tokens.refresh, 'refresh');
    yield call(userService.saveUser, data.user);
    // if(!data.user.hippa || !data.user.medicare || !data.user.permission)
    //   yield call(delayedRedirect, '/acknowledgement',500);
    // else 
    yield call(delayedRedirect, '/dashboard',500);
  }catch(e){
    console.log("error =", e)
    yield put(actions.loginUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while logging in!',{type:"error"});
  }
}

export function* loginUser(action) {
  yield put(actions.loginUserLoading());

  try {
    const data = yield call(
      request,
      `auth/login`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    // console.log("login data ==",JSON.stringify(data));
    // console.log("data =", data)
    toast('You are successfully logged in',{type:"success"});
    // console.log("user =", data.user)
    // if(process.env.REACT_APP_ENV !== "production"){
    //   window.fpr("referral",{email: action.payload.data.email})
    // }

    yield call(userService.saveUserToken, data.tokens.access, 'access');
    yield call(userService.saveUserToken, data.tokens.refresh, 'refresh');
    yield call(userService.saveUser, data.user);

    const date = data.user.createdAt;
    const start = moment(date, 'YYYY-MM-DD');
    const end = moment(new Date(), 'YYYY-MM-DD');
    const diff = end.diff(start, 'days');
    yield put(actions.loginUserSuccess(data.user));
    // if(data.user.subscriptionInfo){
    //   const subscriptionEndDate = moment(data.user.subscriptionInfo.endDate);
    //   // console.log('subscriptionEndDate =', subscriptionEndDate)

    //   if(diff > 30 && moment(new Date()) >= subscriptionEndDate){
    //     yield call(userService.setDashboardVisible, "false");
    //     yield call(delayedRedirect, '/account/1',500);
    //   }else{
    //     yield call(userService.setDashboardVisible, "true");
    //     yield call(delayedRedirect, '/dashboard',500);
    //   }

    // }else{
    //   if(diff > 30 ){
    //     yield call(userService.setDashboardVisible, "false");
    //     yield call(delayedRedirect, '/account/1',500);
    //   }else{
    //     yield call(userService.setDashboardVisible, "true");
    //     yield call(delayedRedirect, '/dashboard',500);
    //   }
    // }

    try{
      const subscription = yield call(
        request,
        `stripe/check-subscription?userId=${data.user.id}`,
        {
          method: 'GET'
        }
      );
      if(subscription.subscriptionDetails){
        if(diff > 30  && subscription?.subscriptionDetails.status !== 'active' ){///&& (moment.unix(subscription?.subscriptionDetails.current_period_end) < moment(new Date()))){
          yield call(userService.setDashboardVisible, "false");
          yield call(delayedRedirect, '/account/1',500);
        }else{
          yield call(userService.setDashboardVisible, "true");
          yield call(delayedRedirect, '/dashboard',500);
        }
      }else{
        if(diff > 30 ){
          yield call(userService.setDashboardVisible, "false");
          yield call(delayedRedirect, '/account',500);
        }else{
          yield call(userService.setDashboardVisible, "true");
          yield call(delayedRedirect, '/dashboard',500);
        }
      }

    
    }catch(er){
      // console.log("diff =", diff)
      if(diff > 30 ){
        yield call(userService.setDashboardVisible, "false");
        yield call(delayedRedirect, '/account/1',500);
      }else{
        yield call(userService.setDashboardVisible, "true");
        yield call(delayedRedirect, '/dashboard',500);
      }
    }
  

  } catch (e) {
    console.log("error =", e)
    yield put(actions.loginUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while logging in!',{type:"error"});
  }
}

export function* registerUser(action) {
  yield put(actions.registerUserLoading());
  try{
    const data = yield call(
      request,
      `users`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    if(process.env.REACT_APP_ENV === "production"){
      window.fpr("referral",{email: action.payload.data.email})
    }
    userService.setSignupUserId(data?.id)
    toast('You are successfully registered. Please verify your email.',{type:"success"});
    yield put(actions.registerUserSuccess(data));
    yield call(delayedRedirect, '/login',500);
  }catch(e){
    yield put(actions.registerUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while registering!',{type:"error"});
  }
}

export function* updateUser(action) {
  yield put(actions.updateUserLoading());
  try{
    const data = yield call(
      request,
      `users/${action.payload.userId}`,
      {
        method: 'PATCH',
        data: action.payload.data,
      }
    );
    yield put(actions.updateUserSuccess(data.user));
    yield call(userService.saveUser, data.user);
    action.payload.successCallback();
    toast('User updated successfully!',{type:"success"});
  }catch(e){
    yield put(actions.updateUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while update!',{type:"error"});
  }
}

export function* getUserProfile(action) {
  yield put(actions.updateUserLoading());
  try{
    const data = yield call(
      request,
      `users/${action.payload.userId}`,
      {
        method: 'GET',
        // data: action.payload.data,
      }
    );
    console.log("user -", data)
    yield put(actions.updateUserSuccess(data));
    yield call(userService.saveUser, data);
    action.payload.successCallback( data);
    // toast('User updated successfully!',{type:"success"});
  }catch(e){
    yield put(actions.updateUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while update!',{type:"error"});
  }
}

export function* forgotPassword(action) {
  yield put(actions.forgotPasswordLoading());
  try{
    const data = yield call(
      request,
      `auth/forgot-password`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    
    yield put(actions.forgotPasswordSuccess(data));
    action.payload.callback()
    toast('Please check your email for link to reset password!',{type:"success"});
    
  }catch(e){
    yield put(actions.forgotPasswordFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  }
}

export function* resetPassword(action) {
  yield put(actions.resetPasswordLoading());
  try{
    const data = yield call(
      request,
      `auth/reset-password`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    toast('Password is reset succussfully!',{type:"success"});
    yield put(actions.resetPasswordSuccess(data));
    action.payload.callback()
   
  }catch(e){
    yield put(actions.resetPasswordFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  }
}

export function* logoutUser(action) {
  yield put(actions.loginUserLoading());

  try {
    const data = yield call(
      request,
      `auth/logout`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
   
    userService.removeUserToken('access');
    userService.removeUserToken('refresh');
    userService.setDashboardVisible('')
    userService.removeUser();
    localforage.clear();
    localStorage.clear()
    yield call(delayedRedirect, '/login', 500);
    if(action.payload.showToast)
      toast('Logout succussfully!',{type:"success"});
  } catch (e) {
    yield put(actions.loginUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while logging out!');
  }
}

export function* getAllPatients(action) {
  if(action.payload.loading)
    yield put(actions.getAllPatientsLoading());

  try {
    const data = yield call(
      request,
      `patients?pageSize=${action.payload.pageSize}&skipCount=${action.payload.skipCount}${action.payload.orderBy ? `&orderBy=${action.payload.orderBy}&orderDir=${action.payload.orderDir}`: ''}${action.payload.searchStr ? `&searchStr=${action.payload.searchStr}`:``}&label=${action.payload.selectedFolder}`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.getAllPatientsSuccess(data.data));
    action.payload.successCallback(data.data)
  } catch (e) {
    yield put(actions.getAllPatientsFailure(e.toString()));
    action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* createColumn(action) {
  yield put(actions.createColumnLoading());

  try {
    const data = yield call(
      request,
      `settings/custom-column`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    toast('Column added successfully!',{type:"success"});
    yield put(actions.createColumnSuccess(data));
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.createColumnFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while creating column!');
  }
}

export function* downloadSampleCSV(action) {
  yield put(actions.downloadSampleCSVLoading());

  try {
    const data = yield call(
      request,
      `csv/download-sample`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.downloadSampleCSVSuccess(data));
    yield call(FileDownload, data, 'sample.csv');
  } catch (e) {
    yield put(actions.downloadSampleCSVFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* getUser(action) {
  yield put(actions.getUserLoading());

  try {
    const data = yield call(
      request,
      `admin/user/${action.payload.userId}`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.getUserSuccess(data));
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.getUserFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  } 
}

export function* getProcessStatus(action) {
  yield put(actions.getProcessStatusLoading());

  try {
    const data = yield call(
      request,
      `humana/last-process-status`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.getProcessStatusSuccess(data));
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.getProcessStatusFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  } 
}

export function* downloadClientCSV(action) {
  yield put(actions.downloadSampleCSVLoading());

  try {
    const data = yield call(
      request,
      `csv/download-patient-list?label=${action.payload.selectedFolder}`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.downloadSampleCSVSuccess(data));
    yield call(FileDownload, data, 'medizues-clients.csv');
  } catch (e) {
    yield put(actions.downloadSampleCSVFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* importCSV(action) {
  yield put(actions.downloadSampleCSVLoading());

  try {
    const data = yield call(
      request,
      `upload/client-list`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    toast('File imported successfully!',{type:"success"});
    yield put(actions.downloadSampleCSVSuccess(data));
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.downloadSampleCSVFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* addPatient(action) {
  yield put(actions.updatePatientLoading());
  try{
    const data = yield call(
      request,
      `patients`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    toast('Beneficiary information added successfully!',{type:"success"});
    yield put(actions.updatePatientSuccess(data));
    //yield call(userService.saveUser, data.user);
    action.payload.successCallback();
    // Toast.success('You are successfully logged in');
  }catch(e){
    yield put(actions.updatePatientFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while adding patient information!',{type:"error"});
  }
}

export function* updatePatient(action) {
  yield put(actions.updatePatientLoading());
  try{
    const data = yield call(
      request,
      `patients/${action.payload.patientId}`,
      {
        method: 'PATCH',
        data: action.payload.data,
      }
    );
    toast('Beneficiary information updated successfully!',{type:"success"});
    yield put(actions.updatePatientSuccess(data));
    //yield call(userService.saveUser, data.user);
    action.payload.successCallback(data.patient);
    // Toast.success('You are successfully logged in');
  }catch(e){
    yield put(actions.updatePatientFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    // toast('Some error occurred while updating patient information!',{type:"error"});
  }
}

export function* deletePatient(action) {
  yield put(actions.updatePatientLoading());
  try{
    const data = yield call(
      request,
      `patients/${action.payload.patientId}`,
      {
        method: 'DELETE',
        // data: action.payload.data,
      }
    );
    action.payload.successCallback();
    toast('Beneficiary information deleted successfully!',{type:"success"});
    yield put(actions.updatePatientSuccess(data));
    //yield call(userService.saveUser, data.user);
   
    // Toast.success('You are successfully logged in');
  }catch(e){
    yield put(actions.updatePatientFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while deleting beneficiary information!',{type:"error"});
  }
}

export function* deleteAllPatient(action) {
  yield put(actions.updatePatientLoading());
  try{
    const data = yield call(
      request,
      `patients`,
      {
        method: 'DELETE',
        data: action.payload.data,
      }
    );
    action.payload.successCallback();
    toast('Records deleted successfully!',{type:"success"});
    // yield put(actions.updatePatientSuccess(data));
    //yield call(userService.saveUser, data.user);
   
    // Toast.success('You are successfully logged in');
  }catch(e){
    yield put(actions.updatePatientFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while deleting information!',{type:"error"});
  }
}

export function* bbAuthorize(action) {
  yield put(actions.bbAuthorizeLoading());

  try {
    const data = yield call(
      request,
      `blue-button/authorize`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    toast('Beneficiary authorized successfully!',{type:"success"});
    action.payload.successCallback(data);
    yield put(actions.bbAuthorizeSuccess(data));
    
  } catch (e) {
    yield put(actions.bbAuthorizeFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* blueButtonRedirect(action) {
  yield put(actions.bbAuthorizeLoading());

  try {
    const data = yield call(
      request,
      `blue-button/redirect?patientId=${action.payload.patientId}&code=${action.payload.code}&state=${action.payload.state}`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.bbAuthorizeSuccess(data));
    redirect(`/dashboard`)
    // action.payload.successCallback(data)
  } catch (e) {
    yield put(actions.bbAuthorizeFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* verifyToken(action) {
  yield put(actions.verifyTokenLoading());
  try{
    const data = yield call(
      request,
      `users/verify-email`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    
    yield put(actions.verifyTokenSuccess(data));
    action.payload.callback()
    toast('Email verified sucessfully. Login to continue!',{type:"success"});
    
  }catch(e){
    yield put(actions.verifyTokenFailure(e.toString()));
    action.payload.errorcallback()
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  }
}

export function* resendVerificationLink(action) {
  yield put(actions.verifyTokenLoading());
  try{
    const data = yield call(
      request,
      `users/resend-verification-email`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    
    yield put(actions.verifyTokenSuccess(data));
    action.payload.callback()
    toast('Email sent sucessfully!',{type:"success"});
    
  }catch(e){
    yield put(actions.verifyTokenFailure(e.toString()));
    action.payload.errorcallback()
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  }
}

export function* authenticatePatients(action) {
  yield put(actions.authenticatePatientsLoading());
  try{
    const data = yield call(
      request,
      `humana/update-patient`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    
    yield put(actions.authenticatePatientsSuccess(data));
    action.payload.successCallback()
    // toast('Email sent sucessfully!',{type:"success"});
    
  }catch(e){
    yield put(actions.authenticatePatientsFailure(e.toString()));
    // action.payload.errorcallback()
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  }
}

export function* fetchBatch(action) {
  yield put(actions.authenticatePatientsLoading());
  try{
    const data = yield call(
      request,
      `humana/update-patient-queue`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    
    yield put(actions.authenticatePatientsSuccess(data));
    action.payload.successCallback(data)
    // toast('Email sent sucessfully!',{type:"success"});
    
  }catch(e){
    yield put(actions.authenticatePatientsFailure(e.toString()));
    // action.payload.errorcallback()
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
  }
}

export function* validateNpn(action) {
  yield put(actions.validateNpnLoading());
  try{
    const data = yield call(
      request,
      `auth/validate-npn`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    // console.log("validate npn res =", data)
    toast(data.data.message, {type:"success"});
    yield put(actions.validateNpnSuccess(data));
    //yield call(userService.saveUser, data.user);
    action.payload.successCallback();
    // Toast.success('You are successfully logged in');
  }catch(e){
    yield put(actions.validateNpnFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while validating NPN!',{type:"error"});
  }
}

export function* fetchPlans(action) {
  yield put(actions.fetchPlansLoading());

  try {
    const data = yield call(
      request,
      `stripe/products`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.fetchPlansSuccess(data));
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.fetchPlansFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* checkSubscription(action) {
  if(!action.payload.hideLoader){
    yield put(actions.checkSubscriptionLoading());
  }

  try {
    const data = yield call(
      request,
      `stripe/check-subscription?userId=${action.payload.userId}`,
      {
        method: 'GET'
      }
    );
    
    yield put(actions.checkSubscriptionSuccess(data));
    if(action.payload.showToastMsgFor){
      toast("Subscribed Successfully",{type:"success"});
    }
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.checkSubscriptionFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    // action.payload.errorCallback(e)
    // if (e.response) {
    //   toast(e.response.data.message,{type:"error"});
    //   return;
    // }
    // toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* initiatePayment(action) {
  yield put(actions.initiatePaymentLoading());

  try {
    const data = yield call(
      request,
      `stripe/init-subscription`,
      {
        method: 'POST',
        data:action.payload.data
      }
    );
   
    yield put(actions.initiatePaymentSuccess(data));
    toast("Your payment was successful!",{type:"success"});
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.initiatePaymentFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* getPaymentMethod(action) {
  yield put(actions.getPaymentMethodLoading());

  try {
    const data = yield call(
      request,
      `stripe/payment-methods`,
      {
        method: 'GET',
        // data:action.payload.data
      }
    );
   
    yield put(actions.getPaymentMethodSuccess(data));
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.getPaymentMethodFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}


export function* updatePaymentMethod(action) {
  yield put(actions.getPaymentMethodLoading());

  try {
     yield call(
      request,
      `stripe/payment-methods`,
      {
        method: 'PUT',
        data:action.payload.data
      }
    );
   
    yield put(actions.getPaymentMethodSuccess());
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback()
    }
  } catch (e) {
    console.log("e==", e)
    yield put(actions.getPaymentMethodFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* deleteSubscription(action) {
  yield put(actions.deleteSubscriptionLoading());

  try {
    const data = yield call(
      request,
      `stripe/subscription`,
      {
        method: 'DELETE',
        data:action.payload.data
      }
    );
   
    yield put(actions.deleteSubscriptionSuccess(data));
    // redirect(`/dashboard`)
    toast("Subscription cancelled successfully!!",{type:"success"});
      if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.deleteSubscriptionFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* getAllFolders(action) {
  if(action.payload.loading)
    yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `label`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.getAllFoldersSuccess(data.data));
    action.payload.successCallback(data.data)
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* updateFolder(action) {
  // yield put(actions.updateFolderLoading());
  try{
    const data = yield call(
      request,
      `label/${action.payload.labelId}`,
      {
        method: 'PATCH',
        data: action.payload.data,
      }
    );
    yield put(actions.updateFolderSuccess(data));
    action.payload.successCallback();
    toast('Folder updated successfully!',{type:"success"});
  }catch(e){
    yield put(actions.updateFolderFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while update!',{type:"error"});
  }
}

export function* addFolder(action) {
  // yield put(actions.addFolderLoading());
console.log(action.payload.data)
  try {
    const data = yield call(
      request,
      `label`,
      {
        method: 'POST',
        data:action.payload.data
      }
    );
    yield put(actions.addFolderSuccess(data));
    toast("Folder created successful!",{type:"success"});
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      console.log('hh')
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.addFolderFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* deleteFolder(action) {
  // yield put(actions.addFolderLoading());

  try {
    const data = yield call(
      request,
      `label/${action.payload.labelId}`,
      {
        method: 'DELETE'
      }
    );
   
    yield put(actions.addFolderSuccess(data));
    // redirect(`/dashboard`)
      toast("Folder deleted successfully!!",{type:"success"});
      if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.addFolderFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* getAllLinks(action) {
  if(action.payload.loading)
    yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `crm-export`,
      {
        method: 'GET'
      }
    );
   
    yield put(actions.getAllFoldersSuccess());
    action.payload.successCallback(data.data)
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* createLink(action) {
  yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `crm-export`,
      {
        method: 'POST',
        data:action.payload.data
      }
    );
   
    yield put(actions.getAllFoldersSuccess());
    toast("Webhook created successfully!",{type:"success"});
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* updateLink(action) {
  yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `crm-export/${action.payload.linkId}`,
      {
        method: 'PATCH',
        data:action.payload.data
      }
    );
   
    yield put(actions.getAllFoldersSuccess());
    toast("Webhook updated successfully!",{type:"success"});
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* deleteLink(action) {
  yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `crm-export/${action.payload.linkId}`,
      {
        method: 'DELETE'
      }
    );
   
    yield put(actions.getAllFoldersSuccess(data));
    // redirect(`/dashboard`)
      toast("Webhook deleted successfully!!",{type:"success"});
      if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    // action.payload.errorCallback(e)
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* exportToCRM(action) {
  yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `patients/export-to-crm`,
      {
        method: 'POST',
        data:action.payload.data
      }
    );
   
    yield put(actions.getAllFoldersSuccess());
    toast("Record pushed successfully!",{type:"success"});
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* bulkExportToCRM(action) {
  yield put(actions.getAllFoldersLoading());

  try {
    const data = yield call(
      request,
      `patients/bulk-export-to-crm`,
      {
        method: 'POST',
        data:action.payload.data
      }
    );
   
    yield put(actions.getAllFoldersSuccess());
    toast("Record pushed successfully!",{type:"success"});
    // redirect(`/dashboard`)
    if(action.payload.successCallback){
      action.payload.successCallback(data)
    }
  } catch (e) {
    yield put(actions.getAllFoldersFailure(e.toString()));
    if(action.payload.errorCallback){
      action.payload.errorCallback(e)
    }
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred!',{type:"error"});
    // Toast.error('Some error occurred while getting patient list!');
  } 
}

export function* moveLabel(action) {
  yield put(actions.moveLabelLoading());
  try{
    const data = yield call(
      request,
      `patients/move-patient`,
      {
        method: 'PATCH',
        data: action.payload.data,
      }
    );
    yield put(actions.moveLabelSuccess(data.user));
    action.payload.successCallback();
    toast('moved successfully!',{type:"success"});
  }catch(e){
    yield put(actions.moveLabelFailure(e.toString()));
    if (e.response) {
      toast(e.response.data.message,{type:"error"});
      return;
    }
    toast('Some error occurred while updating!',{type:"error"});
  }
}

export default function* authSaga() {
  yield takeLatest(actions.loginUser.type, loginUser);
  yield takeLatest(actions.loginGoogleUser.type, loginGoogleUser);
  yield takeLatest(actions.registerUser.type, registerUser);
  yield takeLatest(actions.forgotPassword.type, forgotPassword);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.logoutUser.type, logoutUser);
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.getAllPatients.type, getAllPatients);
  yield takeLatest(actions.createColumn.type, createColumn);
  yield takeLatest(actions.downloadSampleCSV.type, downloadSampleCSV);
  yield takeLatest(actions.downloadClientCSV.type, downloadClientCSV);
  yield takeLatest(actions.importCSV.type, importCSV);
  yield takeLatest(actions.updatePatient.type, updatePatient);
  yield takeLatest(actions.deletePatient.type, deletePatient);
  yield takeLatest(actions.deleteAllPatient.type, deleteAllPatient);
  yield takeLatest(actions.addPatient.type, addPatient);
  yield takeLatest(actions.bbAuthorize.type, bbAuthorize);
  yield takeLatest(actions.blueButtonRedirect.type, blueButtonRedirect);
  yield takeLatest(actions.verifyToken.type, verifyToken);
  yield takeLatest(actions.resendVerificationLink.type, resendVerificationLink);
  yield takeLatest(actions.authenticatePatients.type, authenticatePatients);
  yield takeLatest(actions.validateNpn.type, validateNpn);
  yield takeLatest(actions.fetchPlans.type, fetchPlans);
  yield takeEvery(actions.checkSubscription.type, checkSubscription);
  yield takeLatest(actions.initiatePayment.type, initiatePayment);
  yield takeLatest(actions.getPaymentMethod.type, getPaymentMethod);
  yield takeLatest(actions.updatePaymentMethod.type, updatePaymentMethod);
  yield takeLatest(actions.deleteSubscription.type, deleteSubscription);
  yield takeLatest(actions.fetchBatch.type, fetchBatch);
  yield takeLatest(actions.getAllFolders.type, getAllFolders);
  yield takeLatest(actions.updateFolder.type, updateFolder);
  yield takeLatest(actions.addFolder.type, addFolder);
  yield takeLatest(actions.deleteFolder.type, deleteFolder);
  yield takeLatest(actions.getAllLinks.type, getAllLinks);
  yield takeLatest(actions.createLink.type, createLink);
  yield takeLatest(actions.updateLink.type, updateLink);
  yield takeLatest(actions.deleteLink.type, deleteLink);
  yield takeLatest(actions.exportToCRM.type, exportToCRM);
  yield takeLatest(actions.bulkExportToCRM.type, bulkExportToCRM);
  yield takeLatest(actions.moveLabel.type, moveLabel);
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.getProcessStatus.type, getProcessStatus);
  yield takeLatest(actions.getUserProfile.type, getUserProfile);
}
