import { useState, useEffect } from "react";
import { Col, Drawer, Row, Modal } from "rsuite";
// import SquareButton from "../SquareButton";
// import FileInput from "../FileInput";
// import Toast from "../../../services/toastService";
// import SelectInput from "../SelectInput";
import { toast } from 'react-toastify';
import { InputNumber, Input, SelectPicker } from 'rsuite';

const AddColumnModal = (props) => {

    const [column, setColumn] = useState({
        "column_name":"",
        "label":"",
        "position":8,
        "data_type":"string",
        "category":"custom",
    })

    const [otherColumn, setOtherColumn] = useState({
        "column_name":"",
        "label":"",
        "position":8,
        "data_type":"string",
        "category":"custom"
    })

    useEffect(() => {
        setColumn(
            {
                "column_name":"",
                "label":"",
                "position":8,
                "data_type":"string",
                "category":"custom",
            }
        );
        setOtherColumn(
            {
                "column_name":"",
                "label":"",
                "position":8,
                "data_type":"string",
                "category":"custom",
            }
        );
    },[props])

    const updateColumnValue = (key, value) => {
        let temp = {}
        if(key === "column_name"){
            temp = {
                ...column,
                [key]:value,
                label: props.defaultAvailableColumns.find(x=> x.column_name === value).label
            }
        }else{
            temp = {
                ...column,
                [key]:value
            };
        }
        
       setColumn(temp);
    }

    const updateOtherColumnValue = (key, value) => {
        let temp = {};
        if(key === "column_name"){
            temp = {
                ...otherColumn,
                [key]:value,
                label:value
            };
        }else{
            temp = {
                ...otherColumn,
                [key]:value,
            };
        }
       setOtherColumn(temp);
    }

    const onSave = (type) => {
        if(type === "other"){
            if(!otherColumn.column_name){
                toast('Enter column name!',{type:"error"});
                return;
            }
        }else{
            if(!column.column_name){
                toast('Enter column name!',{type:"error"});
                return;
            }
        }
        props.onSave(type == "other" ? otherColumn : column);
        setColumn({
            "column_name":"",
            "label":"",
            "position":0,
            "data_type":"string",
            "category":"custom"
        });
        setOtherColumn({
            "column_name":"",
            "label":"",
            "position":0,
            "data_type":"string",
            "category":"custom"
        })
        props.onClose()
    }

    return (
        <Modal open={props.isVisible} onClose={props.onClose}>
            <Modal.Header>
                <Modal.Title className="m-5 my-0 ml-0 font-bold text-xl">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow:'hidden'}}>
                <div className="m-5 mt-1 ml-0 font-bold text-lg">Choose from available columns</div>
                <Row className="mb2">
                    <Col md={12} >
                        <div className="ml-1 my-2 font-bold text-sm">Columns</div>
                        <SelectPicker 
                            data={props.defaultAvailableColumns.filter(x => !props.tableColumn.find(y => y.column_name === x.column_name))} 
                            searchable={false} 
                            labelKey="label"
                            valueKey="column_name"
                            value={column.column_name}
                            onChange={(value) => {
                                    updateColumnValue('column_name', value);
                               
                            }}
                            className="w-full"
                            //style={{ width: 200 }} 
                            />
                    </Col>
                    <Col md={12} >
                    <div className="ml-1 my-2 font-bold text-sm">Position</div>
                        <InputNumber 
                            value={column.position} 
                            min={8}
                            onChange={(value, e) => {
                                updateColumnValue('position', value);
                            }} 
                             />
                    </Col>
                </Row>
                <Row>
                    <Col md={16} />
                    <Col md={8}>
                        <div onClick={() => onSave("available")} className="mt-5 font-bold cursor-pointer bg-green-200 px-2 py-2 rounded-md text-white text-center text-sm">{`Save`}</div>
                    </Col>
                </Row>
                
                <div className="m-5 mt-5 ml-0 font-bold text-lg">Choose other</div>
                <Row className="mb2">
                    <Col md={12}>
                        <div className="ml-1 my-2 font-bold text-sm">Enter Column Name</div>
                        <Input
                            value={otherColumn.column_name}
                            onChange={(value, e) => {
                                updateOtherColumnValue('column_name', value);
                            }} 
                        />
                    </Col>
                    <Col md={12}>
                        <div className="ml-1 my-2 font-bold text-sm">Position</div>
                        <InputNumber 
                            value={otherColumn.position} 
                            min={8}
                            onChange={(value, e) => {
                                updateOtherColumnValue('position', value);
                            }} 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={16} />
                    <Col md={8}>
                        <div onClick={() => onSave("other")} className="mt-5 font-bold cursor-pointer bg-green-200 px-2 py-2 rounded-md text-white text-center text-sm">{`Save`}</div>
                    </Col>
                </Row>
                </Modal.Body>
            {/* <Modal.Footer>
                <SquareButton onClick={() => props.onClose()} secondary className="mr2">Cancel</SquareButton>
                <SquareButton onClick={() => props.onSave()}>Save</SquareButton>
            </Modal.Footer> */}
        </Modal>
    );
};

AddColumnModal.defaultProps = {
    title: "Add Column",
    show: false,
    onHide: () => { },
    onSubmit: () => { },
};

export default AddColumnModal;
