import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { redirect, delayedRedirect } from "../../../utils/response";
import { useDispatch, useSelector } from "react-redux";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Toast from "../../../services/toastService";
import  Header  from "../../components/Header";
import * as Yup from "yup";
import { toast } from 'react-toastify';

export const ResetPassword = () => {

    const FPSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email Required"),
    });

    const RPSchema = Yup.object().shape({
        password: Yup.string()
          .required("No password provided.")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain one uppercase, one lowercase, one number and one special case character."),
        cpassword: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain one uppercase, one lowercase, one number and one special case character."),
    });

    const [user, setUser] = useState({email:''});
    const [showPassword, setShowPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)
    const dispatch = useDispatch();
    const location = useLocation();
    let { token } = useParams(); 
   
    const saveData = (values) => {
        dispatch({
            type: authActions.forgotPassword.type,
            payload: {
                data: values,
                callback: (token) => {
                    //redirect(`/reset-password?token=${token}`)
                    
                }
            }
        })
    }

    const resetPassword = (values) => {
        if(!(values.password.length > 0 && values.password === values.cpassword)){
            
            toast('Password dont match!',{type:"error"});
            return;
        }
        dispatch({
            type: authActions.resetPassword.type,
            payload: {
                data: {
                    token: token,
                    password: values.password
                },
                callback: () => {
                    redirect(`/login`)
                    // Toast.success('Password set successfullly!');
                }
            }
        })
    }

    return(
        <div className="font-Poppins pb-10 overflow-y-scroll overflow-x-hidden bg-green-100 w-full h-screen">
             <Header/>
            <div className="flex flex-wrap mt-0 w-full bg-green-100 h-full">
                <div className="w-full md:w-1/2 lg:w-1/2 z-[100] mt-10 p-4 pb-16 md:pl-16 lg:pl-16 md:p-0 lg:p-0">
                    {
                        token ? 
                        <Formik
                        validationSchema={RPSchema}
                        initialValues = {user}
                        onSubmit={(values) => {
                            resetPassword(values);
                        }}
                        >
                        {
                            ({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleChange,
                                setValues,
                            }) => (
                                <Form>
                                    <div className="bg-white rounded-2xl p-5 w-full px-10 pt-20 pb-32">
                                        <h1 className="font-extrabold text-xl text-gray-600">Reset Password</h1>
                                        <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                                        <p className="py-3 text-gray-500 text-lg">Reset Password for your MediZues account!</p>
        
                                        
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-5 ">
                                                <p className="text-gray-500 text-md font-bold">Enter Password</p>
                                                <div className="flex items-center ">
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type={showPassword ? "text": "password"}
                                                    name="password"
                                                    value={values?.password}
                                                    required
                                                    placeholder=""
                                                />
                                                 <img onClick={() => setShowPassword(!showPassword)} className="cursor-pointer h-6 -ml-10 mt-1" src="/images/eye_grey.png"  />
                                                </div>
                                                {errors.password && touched.password ? (
                                                    <p className="mt-2 text-red-500 text-xs">{errors.password}</p>
                                                ) : null}
                                            </div>
                                            <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-5 ">
                                                <p className="text-gray-500 text-md font-bold">Confirm Password</p>
                                                <div className="flex items-center ">
                                                <Field
                                                    className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                    type={showCPassword ? "text": "password"}
                                                    name="cpassword"
                                                    value={values?.cpassword}
                                                    required
                                                    placeholder=""
                                                />
                                                 <img onClick={() => setShowCPassword(!showCPassword)} className="cursor-pointer h-6 -ml-10 mt-1" src="/images/eye_grey.png"  />
                                                </div>
                                                 {errors.cpassword && touched.cpassword ? (
                                                    <p className="mt-2 text-red-500 text-xs">{errors.password}</p>
                                                ) : null}
                                            </div>
                                    
                                        <div className="font-semibold text-gray-400 flex gap-x-5 items-center mt-10 w-full justify-end">
                                            <button type="submit" className="font-bold text-white bg-green-200 py-1 px-8 rounded-md">{`Reset >`}</button>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                        </Formik>
                    
                        :
                        <Formik
                        validationSchema={FPSchema}
                        initialValues = {user}
                        onSubmit={(values) => {
                        saveData(values);
                    }}
                    >
                    {
                        ({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            handleChange,
                            setValues,
                        }) => (
                            <Form>
                                <div className="bg-white rounded-2xl p-5 w-full px-10 pt-20 pb-32">
                                    <h1 className="font-bold text-xl text-gray-600">Reset Password</h1>
                                    <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                                    <p className="py-3 text-gray-500 text-lg">Account's email for which password needs to reset!</p>
    
                                    
                                        <div className="rounded-2xl border-2 border-green-100 p-2 text-center my-5 ">
                                            <p className="text-gray-500 text-md font-bold">Email</p>
                                            <Field
                                                className="text-center text-gray-500 mt-2 bg-green-100 w-full rounded-lg p-2 focus:bg-green-100 focus:outline focus:outline-1 focus:outline-green-200"
                                                type="text"
                                                name="email"
                                                value={values?.email}
                                                required
                                                placeholder=""
                                            />
                                            {errors.email && touched.email ? (
                                            <p className="mt-2 text-red-500 text-xs">{errors.email}</p>
                                        ) : null}
                                        </div>
                                       
                                
                                    <div className="font-semibold text-gray-400 flex gap-x-5 items-center mt-10 w-full justify-end">
                                        <button type="submit" className="font-bold text-white bg-green-200 py-1 px-8 rounded-md">{`Send >`}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                    </Formik>
                   
                    }
                
                </div>
                <div className="hidden md:block lg:block w-full md:w-1/2 lg:w-1/2 mt-10">
                    {/* <img src="/images/splash.png"  /> */}
                    <div className="z-0 w-full flex justify-between relative">
                        <img src="/images/cloud_1.png" className="z-0 w-2/6 h-42 object-contain absolute pt-52" />
                        <img src="/images/zues.png"  className="w-4/6 h-80 object-contain pt-20"/>
                        <img src="/images/cloud_2.png" className="z-0 w-2/6 h-42 object-contain pt-96" />
                    </div>
                    <div className="lg:-mt-52 md:-mt-44 z-[100] relative">
                        <div className="w-full flex justify-center">
                            <img alt="" src="/images/medizues-text.png" className="h-24 w-60 object-contain item-center" />
                        </div>
                        {/* <h1 className=" text-center text-orange-200 font-bold text-4xl">MediZues</h1> */}
                        <h1 className="text-center text-green-200 font-bold text-lg mt-2">Get god-like insights on your clients behavior</h1>
                        {/* <h1 className=" text-center text-orange-200 font-bold text-4xl">The mighty<br/> ZuesRx extension</h1> */}
                        {/* <h1 className="text-center text-green-200 font-bold text-lg mt-2">simply makes the most of your sheets!</h1> */}
                    </div>
                    
                </div>
            </div>
            
        </div>
    )
}