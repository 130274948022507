import { useEffect, useState } from "react";
import { Modal, SelectPicker } from "rsuite";
import ConfirmationModel from "../ConfirmationModel";

const MoveDataModel = (props) => {
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [selectedFolder,setSelectedFolder] = useState(props.selectedFolder)
    const [folderData,setFolderData] = useState(props.folders.filter(x => x.id !== props.selectedFolder.id))


    const onCancel = () => {
        props.onClose()
    }

    useEffect(() => {
        setFolderData(props.folders.filter(x => x.id !== props.selectedFolder.id).map(item=>
        ({label:item.label,value:item.id})))
    }, [props.isVisible])

    const handleChangeFolder = (e)=>{
        let folder = props.folders.filter(item=>item.id == e)
        setSelectedFolder(folder[0])
    }

    return isModalOpen ? (
        <ConfirmationModel
        isVisible={isModalOpen}
        title=""
        message={<div>{`Are you sure you want to move the selected rows to ${selectedFolder?.label} folder ?`}</div>}
        onSave={() => {
            props.onSelect(selectedFolder)
        }}
        onClose={() => {
            setIsModalOpen(false)
            setSelectedFolder()
        }}
        continueName="Move"
        cancelName="No"
      />
    )
    :
    (
        <Modal open={props.isVisible} onClose={onCancel} className="modal-container">
            <Modal.Header>
                <Modal.Title className="font-Poppins m-5 my-0 ml-0 font-bold text-xl">{}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="font-Poppins">
                   <div className="text-center text-xl text-green-200 font-extrabold">{props.message}</div>
                   <div className="flex justify-center gap-x-5 items-center pb-14">
                   <label className="font-bold">Folders: </label>
                   <SelectPicker className="w-1/2" data={folderData} onChange={e=>handleChangeFolder(e)} searchable={false} />
                   </div>
                   <div className="flex justify-center gap-x-3 mt-10">
                        {
                            (props.cancelName) && (
                                <div onClick={onCancel} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                                    {`< ${props.cancelName}`}
                                </div>
                            )
                        }
                        <div onClick={(e)=>{
                            e.preventDefault()
                            props.onSelect(selectedFolder)
                        }} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg ">
                           {`${props.continueName ? props.continueName : 'Move'} >`}
                        </div>
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
};

MoveDataModel.defaultProps = {
    title: "Add Column",
    show: false,
    onHide: () => { },
    onSubmit: () => { },
};

export default MoveDataModel;
