import React, {useState, useEffect, useRef} from 'react';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import  Header  from "../../components/Header";
import { delayedRedirect, redirect } from '../../../utils/response';

export const Bluebutton = () => {

    // let { patientId, code, state } = useParams();
    const dispatch = useDispatch()
    const location = useLocation()
    const isMounted = useRef(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const patientId = queryParams.get('patientId')
        const code = queryParams.get('code')
        const state = queryParams.get('state')
        console.log("patientId =", patientId, "code =", code, "state ==",state);
        if (isMounted.current) {
            dispatch({
                type: authActions.blueButtonRedirect.type,
                payload: {
                    patientId: patientId,
                    code: code,
                    state: state,
                    successCallback: (token) => {
                        // redirect(`/data-list`)
                    }
                }
            })
        }else {
            isMounted.current = true;
        }
    },[])

    return (
        <div className="bg-green-100 w-full h-screen">
            <Header/>
        </div>
    )

}

