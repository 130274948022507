import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import authService from "../../../services/authService";
import { redirect } from "../../../utils/response";
// import userService from "../../../services/userService";

const excludedPaths = ['/login', '/sign-up', '/plans', '/payment' ,'/reset-password', '/set-password', '/verify-email', '/complete-customization', '/payment/success'];

const AuthCheckProvider = ({ children }) => {
    const location = useLocation();

    const run = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        let dashboardVisible = true;
        // if(isAuthenticated){
        //     const dashboardView = await userService.getDashboardVisible();
        //     dashboardVisible = (dashboardView === "false" ? false : true);
        // }
        if (!isAuthenticated && !excludedPaths.some((path) => location.pathname.includes(path) ) ) {
            redirect('/login');
        }else if(isAuthenticated && excludedPaths.some((path) => location.pathname.includes(path)) ){
            redirect('/dashboard');
        }
        // else if(isAuthenticated && !dashboardVisible && excludedPaths.some((path) => location.pathname.includes(path)) ){
        //     redirect('/account');
        // }
    };

    useLayoutEffect(() => {
        run();
    }, [location]);

    return (
        <>
            {children}
        </>
    );
};

export default AuthCheckProvider