import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { selectAuth } from './../../shared-redux/Auth/selectors';
import  Header  from "../../components/Header";
import { useNavigate } from "react-router-dom";
import userService from '../../../services/userService';
import { redirect } from '../../../utils/response';
import { toast } from 'react-toastify';

export const Acknowledgement = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
   
    const [user, setUser] = useState({
         permission:false,
        hippa:false,
        medicare:false});
    const [userId, setUserId] = useState(null);
    const [refreshToken, setrefreshToken] = useState()

    console.log("user ==",user)

    const checkLoggedInUser = async() => {
        const refreshToken = await userService.getUserToken('refresh');
        // console.log("refreshToken ==", refreshToken)
        if(refreshToken) setrefreshToken(refreshToken.token)
    }

    useEffect(() => {
        checkLoggedInUser()
        userService.getCurrentUserId().then(value => {
            // console.log("userId= ==", value)
            setUserId(value);
          });
      
          userService.getUser().then(value => {
            console.log("value user= ==", value)
            if (value)
              setUser(value);
          });

    },[])

    const onRun = (values) => {
        if(!values.permission || !values.hippa || !values.medicare){
            toast("Please agree on all terms to continue!",{type:"error"});
            return;
        }
        console.log("values= ==", values)
        dispatch({
            type: authActions.updateUser.type,
            payload: {
                data: values,
                userId,
                successCallback:() => {
                    redirect('/dashboard')
                }
            }
        })
    }

    const OnBack = () => {
        // navigate(-1)
        dispatch({
            type: authActions.logoutUser.type,
            payload: {
                showToast:false,
                data: {
                    refreshToken
                }
            }
        });
    }

    return(
        <div className="font-Poppins bg-green-100 w-full h-screen">
            <Header/>
            {/* <div className="w-full p-8 flex pl-16">
                <img src="/images/logo.png" className="h-16" />
            </div> */}
            <div className="flex w-full">
                <div className="w-full z-[100] mt-0 px-16">
                <Formik
                    initialValues = {user}
                    onSubmit={(values) => {
                    onRun(values);
                }}
                >
                {
                    ({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        setValues,
                    }) => (
                        
                        <Form>
                            <div>
                                <div className="bg-white rounded-2xl p-5 w-full px-10 py-10">
                                <h1 className="font-bold text-xl text-gray-600 text-center">Acknowledgements</h1>
                                    <div className="rounded-2xl bg-green-100 p-5 my-5">
                                        <Field type="checkbox"
                                            className="accent-green-200"
                                            name="permission"
                                            checked={values.permission ? true : false}
                                            id="permission" />
                                        <label htmlFor="permission" className="ml-2 text-gray-500 font-light text-xs">
                                            I have explicit permission from every single individual to perform each of the following activities:<br/><br/>
                                            <ul className="list-square pl-10">
                                            <li>Access their Medicare gov account</li>
                                            <li>Look at their plan information</li>
                                        </ul>
                                        </label>
                                    </div>
                                    <div className="mt-2 rounded-2xl bg-orange-100 p-5 my-5 ">
                                        <Field type="checkbox"
                                            className="accent-green-200"
                                            name="hippa"
                                            checked={values.hippa ? true : false}
                                            id="hippa" />
                                        <label htmlFor="hippa" className="ml-2 text-gray-500 font-light text-xs">
                                            I have been, and will continue to comply with all HIPAA regulations before and after using this software program.
                                        </label>
                                    </div>
                                    <div className="mt-2 rounded-2xl bg-orange-100 p-5 my-5 ">
                                        <Field type="checkbox"
                                            className="accent-green-200"
                                            name="medicare"
                                            checked={values.medicare ? true : false}
                                            id="medicare" />
                                        <label htmlFor="medicare" className="ml-2 text-gray-500 font-light text-xs">
                                            MediZues is not responsible for any unauthorized use of client's medicare.gov account; I am.
                                        </label>
                                    </div>
                            </div>
                            
                                <div className="p-5 w-full px-10 py-10 flex justify-center gap-x-5">
                                    <div onClick={OnBack} className="cursor-pointer bg-white px-12 py-2 rounded-lg  text-gray-500 shadow-lg text-sm font-bold">{`< Cancel`}</div>
                                    <button type="submit" className="cursor-pointer bg-green-100 px-12 py-2 rounded-lg  text-gray-500 shadow-lg text-sm font-bold">{`Agree >`}</button>
                                </div>
                            </div>
                        </Form>
                    )
                }
                </Formik>
               
                </div>
               
            </div>
            
        </div>
    )
}