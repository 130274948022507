import { useState } from "react";
import { Col, Drawer, Row, Modal } from "rsuite";
// import SquareButton from "../SquareButton";
// import FileInput from "../FileInput";
// import Toast from "../../../services/toastService";
// import SelectInput from "../SelectInput";
import { InputNumber, Input, SelectPicker } from 'rsuite';
import { Uploader } from 'rsuite';
import { toast } from 'react-toastify';

const ConfirmationModel = (props) => {

    const onSave = () => {
        // const file
        setTimeout(() => {
            props.onSave();
        },1000)
       
        onCancel()
    }

    const onCancel = () => {
        props.onClose()
    }

    return (
        <Modal open={props.isVisible} onClose={onCancel} className="modal-container">
            <Modal.Header>
                <Modal.Title className="m-5 my-0 font-bold text-3xl flex w-full justify-center text-center">{props?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="font-Poppins">
                   <div className="text-center text-base font-bold">{props.message}</div>
                    
                    <div className="flex justify-center gap-x-3 mt-10">
                        <div onClick={onSave} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg ">
                           {`${props.continueName ? props.continueName : 'Delete'} >`}
                        </div>
                    </div>
                    
                </div>
                    
            </Modal.Body>
            
        </Modal>
    );
};

ConfirmationModel.defaultProps = {
    title: "Add Column",
    show: false,
    onHide: () => { },
    onSubmit: () => { },
};

export default ConfirmationModel;
