import React,{ useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Login } from "./containers/Login";
import { SignUp } from "./containers/SignUp";
import { ResetPassword } from "./containers/ResetPassword"
import { Acknowledgement } from "./containers/Acknowledgement";
import { DataList } from "./containers/DataList";
import { Bluebutton } from "./containers/Bluebutton";
import { VerifyEmail } from "./containers/VerifyEmail";
import { Account } from "./containers/Account";
import Loader from "./components/Loader";
import AuthCheckProvider from "./components/AuthCheckProvider";
import { from } from "array-flatten";
import { PageLoader } from "../services/LoaderService";
import Auth from "./components/Auth";
import authService from "../services/authService";
import {useToaster } from "rsuite"
import { Plans } from "./containers/Plans";
import { Payment } from "./containers/Payment";

function App() {
  const toaster = useToaster();
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkLoggedInUser = async() => {
   
    const isAuthenticated = await authService.isAuthenticated();
    setIsLoggedIn(isAuthenticated);
}

useEffect(() => {
    checkLoggedInUser();

},[]);

  return (
    <AuthCheckProvider>
      <HelmetProvider>
          <Routes>
          
            {/* <Route exact path="/" element={<Login/>} /> */}
            <Route exact path="/login" element={<Login/>} loader={Loader}/>
            <Route exact path="/sign-up" element={<SignUp/>} loader={Loader}/>
            <Route exact path="/plans" element={<Plans/>} loader={Loader}/>
            <Route exact path="/payment/:id" element={<Payment/>} loader={Loader}/>
            <Route exact path="/reset-password" element={<ResetPassword/>} loader={Loader}/>
            <Route exact path="/reset-password/:token" element={<ResetPassword/>} loader={Loader} />
            <Route exact path="/acknowledgement" element={<Acknowledgement/>} loader={Loader}/>
            <Route exact path="/" element={!isLoggedIn ? <Login/> : <DataList/>} loader={Loader}/>
            <Route exact path="/dashboard" element={<DataList/>} loader={Loader}/>
            <Route exact path="/account" element={<Account/>} loader={Loader}/>
            <Route exact path="/account/:tab" element={<Account/>} loader={Loader}/>
            <Route exact path="/bluebutton" element={<Bluebutton/>} loader={Loader} />
            <Route exact path="/verify-email" element={<VerifyEmail/>} loader={Loader} />
          </Routes>
          
      </HelmetProvider> 
      <PageLoader /> 
     </AuthCheckProvider>
  );
}

export default App;