import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import loadingMiddleware from './../services/LoaderService';
import authSaga from './../app/shared-redux/Auth/saga';
import { createReducer } from './reducers';

export function configureAppStore() {

    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const { run: runSaga } = sagaMiddleware;
  
    const loaderMiddleware = loadingMiddleware({
      promiseTypeSuffixes: ['Loading', 'Success', 'Failure|Error'],
    });
  
    // Create the store with saga middleware
    const middlewares = [
      sagaMiddleware,
      loaderMiddleware,
    ];
  
    // const enhancers = [
    //   createInjectorsEnhancer({
    //     createReducer,
    //     runSaga,
    //   }),
    // ];
  
    const store = configureStore({
      reducer: createReducer(),
      middleware: [
        ...getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [
              FLUSH,
              REHYDRATE,
              PAUSE,
              PERSIST,
              PURGE,
              REGISTER,
            //   actions.updateClientImage.type,
            //   hoWToActions.saveHowTo.type,
            //   hoWToActions.updateHowTo.type,
            //   clientResourcesActions.uploadMedia.type,
            //   clientResourcesActions.deleteMedia.type,
            //   formBuilderActions.updateForm.type,
            //   legalRequestActions.inviteSubUser.type,
            //   legalRequestActions.saveRequest.type,
            //   legalRequestActions.assignLegalRequest.type,
            //   legalRequestActions.deleteArtefact.type,
            //   userActions.fetchSubscriberList.type,
            ],
          },
        }),
        ...middlewares,
      ],
      // devTools: process.env.NODE_ENV !== 'production',
      // enhancers,
    });
    sagaMiddleware.run(authSaga)

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    // if (module.hot) {
    //   module.hot.accept('./reducers', () => {
    //     forceReducerReload(store);
    //   });
    // }
    const persistor = persistStore(store);
    return { store, persistor };
  }
  
