import { useEffect, useState } from "react";
import { Col, Drawer, Row, Modal, Checkbox } from "rsuite";
// import SquareButton from "../SquareButton";
// import FileInput from "../FileInput";
// import Toast from "../../../services/toastService";
// import SelectInput from "../SelectInput";
import { InputNumber, Input, SelectPicker } from 'rsuite';
import { Uploader } from 'rsuite';
import { toast } from 'react-toastify';
import EditIcon from "@rsuite/icons/Edit";
import CloseIcon from "@rsuite/icons/Close";
import CheckIcon from "@rsuite/icons/Check";
import ConfirmationModel from "../ConfirmationModel";

const PushDataModel = (props) => {
    const [isEditable,setIsEditable] = useState({})
    const [webhookData,setWebhookData] = useState([])
    const [selectedWebhooks,setSelectedWebhooks] = useState([])
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [selectedWebhookId,setSelectedWebhookId] = useState("")
    const [addNewOpen, setAddNewOpen] = useState(false)
    const [newNickname, setNewNickname] = useState('');
    const [newExportLink, setNewExportLink] = useState('');

    useEffect(() => {
        setWebhookData([...props.allWebhooks])
    }, [props.allWebhooks])
    

    // const onSave = () => {
    //     // const file
    //     setTimeout(() => {
    //         props.onSave();
    //     },1000)
       
    //     onCancel()
    // }

    const onCancel = () => {
        setSelectedWebhooks([])
        setIsEditable({})
        props.onClose()
    }

    const onPush = () => {
        props.onPushToWebhooks(webhookData.filter(x => selectedWebhooks.indexOf(x.id) !== -1))
    }

    const handleInputChange = (type,val,webhook) => {
        let tempwebhooks = [...webhookData]
        tempwebhooks.map(e=>{
            if(e.id==webhook.id){
                if(type == 'nickname'){
                    e.exportLinkName = val
                }else{
                    e.exportLink = val
                }
            }
        })
        setWebhookData(tempwebhooks)
    }

    const closeAddNewWebhookModal = () => {
        setAddNewOpen(false)
        setNewExportLink('')
        setNewNickname('')
    }

    const onAddNewWebhook = () => {
        setAddNewOpen(true)
        // setWebhookData([...webhookData, {exportLink:'', exportLinkName: ''}])
    }

    const onSaveWebhook = () => {
        if(newNickname.length < 1) {
            toast.error('Add Nickname')
            return
        }
        if(newExportLink.length < 1) {
            toast.error('Add Destination URL')
            return
        }
        const data = {
            exportLinkName: newNickname,
            exportLink: newExportLink
        }
        props.onCreate(data)
        closeAddNewWebhookModal()
    }

    return isModalOpen ? (
        <ConfirmationModel
        isVisible={isModalOpen}
        title=""
        message={<div>Are you sure you want to delete this Webhook?</div>}
        onSave={() => {
            props.onDelete(selectedWebhookId)
        }}
        onClose={() => setIsModalOpen(false)}
        continueName="Delete"
        cancelName="No"
      />
    ) :
    (addNewOpen) ? (
        <Modal open={addNewOpen} onClose={() => closeAddNewWebhookModal()} className="modal-container">
            <Modal.Header>
                <Modal.Title className="font-Poppins m-5 my-0 ml-0 font-extrabold text-xl text-green-200">{"Add New Webhook"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-full font-Poppins">
                    <div className="grid grid-cols-7 gap-2 w-full mb-2">
                        <div className="col-span-2 font-bold">Nickname</div>
                        <div className="col-span-5 font-bold">Destination URL</div>
                    </div>
                    <div className="grid grid-cols-7 gap-2 w-full mb-2">
                        
                        <div className="col-span-2 px-2 text-base border border-green-200 flex justify-between rounded-lg">
                            <div className="flex w-full justify-between items-center">
                                <input
                                placeholder="Enter Name"
                                value={newNickname}
                                onChange={(e)=> {setNewNickname(e.target.value)}}
                                className="bg-transparent border-none w-full p-2 text-start text-base placeholder:text-stone-300" />
                                {/* <div className="flex my-auto w-4/12 items-center justify-center gap-x-2">
                                    <CheckIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                        }}
                                        style={{ fontSize: 14, cursor:'pointer' }}
                                    />
                                    <CloseIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            setIsEditable({})
                                        }}
                                        style={{ fontSize: 14, marginRight: 0, cursor:'pointer' }}
                                    />
                                </div> */}
                            </div>
                        </div>
                        
                        <div className="col-span-5 px-4 text-base border border-green-200 flex justify-between rounded-lg">
                            <div className="flex w-full justify-between items-center">
                                <input 
                                placeholder="Enter url"
                                value={newExportLink}
                                onChange={(e)=> {setNewExportLink(e.target.value)}}
                                className="bg-transparent border-none w-full p-2 text-start text-base placeholder:text-stone-300"/>
                                {/* <div className="flex my-auto w-2/12 items-center justify-center gap-x-2">
                                    <CheckIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            setIsEditable({})
                                        }}
                                        style={{ fontSize: 14, marginRight: 6, cursor:'pointer' }}
                                    />
                                    <CloseIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            setIsEditable({})
                                        }}
                                        style={{ fontSize: 14, marginRight: 0, cursor:'pointer' }}
                                    />
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                    <div className="flex justify-center mt-10">
                        <div onClick={onSaveWebhook} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                            {`Add Webhook >`}
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
    )
    :
    (
        <Modal open={props.isVisible} onClose={onCancel} className="modal-container">
            <Modal.Header>
                <Modal.Title className="font-Poppins m-5 my-0 ml-0 font-bold text-xl">{}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="font-Poppins">
                   <div className="text-center text-xl text-green-200 font-extrabold">{props.message}</div>
                   <div className="grid grid-cols-9 gap-2 w-full mb-2">
                        <div className="col-span-1"></div>
                        <div className="col-span-2 font-bold">Nickname</div>
                        <div className="col-span-5 font-bold">Destination URL</div>
                        <div className="col-span-1"></div>
                   </div>
                   {
                     webhookData && webhookData.map((item,index) => (
                        <div key={item.id} className="grid grid-cols-9 gap-2 w-full mb-2">
                            
                            <div className="flex col-span-1 items-center">
                            <Checkbox onChange={(e,checked)=>{
                                if(checked){
                                    let temp = [...selectedWebhooks]
                                    temp.push(item.id)
                                    setSelectedWebhooks(temp)
                                }else{
                                    let temp = [...selectedWebhooks]
                                    setSelectedWebhooks(temp.filter(e=>e!==item.id))
                                }
                            }} className="-mt-1 mr-3"/>
                            {index+1}
                            </div>
                           
                            <div className="col-span-2 px-2 text-base border border-green-200 flex justify-between rounded-lg">
                            {(isEditable && isEditable.type == 'nickname' && isEditable.row == index) ?
                               <div className="flex w-full justify-between items-center">
                                <input
                                onChange={(e)=>handleInputChange('nickname',e.target.value,item)}
                                className="bg-transparent border-none w-8/12 text-start text-sm" value={item.exportLinkName}/>
                                <div className="flex my-auto w-4/12 items-center justify-center gap-x-2">
                                    <CheckIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            props.onUpdate(item,item.id)
                                            setIsEditable({})
                                        }}
                                        style={{ fontSize: 14, cursor:'pointer' }}
                                    />
                                    <CloseIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            setIsEditable({})
                                        }}
                                        style={{ fontSize: 14, marginRight: 0, cursor:'pointer' }}
                                    />
                                </div>
                                </div>
                            : 
                            <div className="flex w-full justify-between items-center">
                                <span className="break-word w-8/12 text-sm">{item.exportLinkName}</span>
                                <EditIcon
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        setIsEditable({type:'nickname',row:index})
                                    }} className="w-4/12"
                                    style={{ fontSize: 14,marginTop:'auto',marginBottom:'auto',marginRight: 0, cursor:'pointer' }}
                                />
                            </div>
                            }
                            </div>
                            
                            <div className="col-span-5 px-4 text-base border border-green-200 flex justify-between rounded-lg">
                            {(isEditable && isEditable.type == 'url' && isEditable.row == index) ? 
                                 <div className="flex w-full justify-between items-center">
                                    <input 
                                    onChange={(e)=>handleInputChange('url',e.target.value,item)}
                                    className="bg-transparent border-none w-10/12 text-start text-sm" value={item.exportLink}/>
                                    <div className="flex my-auto w-2/12 items-center justify-center gap-x-2">
                                        <CheckIcon
                                            onClick={(e)=>{
                                                e.preventDefault()
                                                props.onUpdate(item,item.id)
                                                setIsEditable({})
                                            }}
                                            style={{ fontSize: 14, marginRight: 6, cursor:'pointer' }}
                                        />
                                        <CloseIcon
                                            onClick={(e)=>{
                                                e.preventDefault()
                                                setIsEditable({})
                                            }}
                                            style={{ fontSize: 14, marginRight: 0, cursor:'pointer' }}
                                        />
                                    </div>
                                </div>
                                : 
                                <div className="flex w-full justify-between items-center">
                                     <span className="break-all w-10/12 text-sm">{item.exportLink}</span>
                                    <EditIcon
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            setIsEditable({type:'url',row:index})
                                        }} className="w-2/12"
                                        style={{ fontSize: 14,marginTop:'auto',marginBottom:'auto', marginRight: 0, cursor:'pointer' }}
                                    />
                                </div>
                            }
                            </div>
                           
                            <div className="col-span-1 flex items-center justify-center" onClick={(e=>{
                                e.preventDefault()
                                setSelectedWebhookId(item.id)
                                setIsModalOpen(true)
                            })}>
                                <img style={{objectFit:'contain'}} alt="" className="h-5 cursor-pointer w-5" src="/images/trash.png" />
                            </div>
                           
                        </div>
                        ))
                   }

                   
                    {/* {
                        webhookData && webhookData.map((x, index)=> (
                            <Webhook data ={x} index={index}/>
                        ))
                    } */}
                    <div onClick={onAddNewWebhook} className="flex font-extrabold text-green-200 justify-center mt-3 underline cursor-pointer">
                        Add a new Webhook destination
                    </div>
                    <div className="w-5/6 mt-5 ml-5">
                   
                    </div>
                    
                    <div className="flex justify-center gap-x-3 mt-10">
                        {
                            (props.cancelName) && (
                                <div onClick={onCancel} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-orange-200 text-white rounded-tl-lg rounded-br-lg ">
                                    {`< ${props.cancelName}`}
                                </div>
                            )
                        }
                        <div onClick={(e)=>{
                            e.preventDefault()
                            if(selectedWebhooks.length > 0){
                                onPush()
                            }else{
                                toast.error('Please select webhook')
                            }
                        }} className="cursor-pointer flex items-center gap-x-2 shadow-lg font-bold px-10 py-2 text-base bg-green-200 text-white rounded-tl-lg rounded-br-lg ">
                           {`${props.continueName ? props.continueName : 'Delete'} >`}
                        </div>
                        
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
    )
};

PushDataModel.defaultProps = {
    title: "Add Column",
    show: false,
    onHide: () => { },
    onSubmit: () => { },
};

export default PushDataModel;
