/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { persistReducer } from 'redux-persist';
import localForage from 'localforage';
import { loadingReducer } from './../services/LoaderService';
import { reducer as authReducer } from './../app/shared-redux/Auth/slice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  // if (Object.keys(injectedReducers).length === 0) {
  //   return state => state;
  // } else {
    const rootReducer = combineReducers({
      ...injectedReducers,
      loader: loadingReducer,
      authReducer: authReducer
    });

    const persistConfig = {
      key: 'root',
      storage: localForage,
      debug: true,
      whitelist: ['loginRegister'],
    };

    let persistedRootReducer = persistReducer(persistConfig, rootReducer);

    return persistedRootReducer;
  // }
}
