import React, {  useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import  Header  from "../../components/Header";
import { redirect } from '../../../utils/response';
import { actions as authActions } from './../../shared-redux/Auth/slice';
import { toast } from 'react-toastify';

export const Plans = () => {
    const [activePlans,setActivePlans] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch({
            type: authActions.fetchPlans.type,
            payload: {
                successCallback: (data) => {
                    setActivePlans(data)
                }
            }
        })
    }, [])
    

    const RedirectPayment = (paymentDetail) => {
        // history.push('./payment')
        redirect(`/payment/${paymentDetail.id}`)
    }

    return(
        <div className="font-Poppins pb-10 bg-green-100 w-full h-screen overflow-y-scroll overflow-x-hidden">
            <Header/>
            <div className="flex flex-wrap mt-0 w-full bg-green-100 h-4/5">
                <div className="w-full z-[100] h-full mt-10 p-4 px-16">
                <div className="bg-white rounded-2xl p-5 w-full px-10 py-10 h-full">
                    <h1 className="font-bold text-xl text-gray-600">Plans</h1>
                    <div className="bg-gradient-to-r from-orange-200 to-green-200 h-2 w-12 rounded mt-2"></div>
                    <p className="py-3 text-gray-500 text-lg">Choose the plan that works for you.</p>
                    <div className="w-full flex gap-x-4 px-0 md:px-8 lg:px-8 mt-8 h-2/4" style={{alignItems:'center',justifyContent:'center'}}>
                    {
                        activePlans && activePlans.map((item)=>(
                            <div className={`cursor-pointer w-1/3 grid border border-solid rounded-lg px-5 pt-1 pb-8 font-bold justify-center plan-card`}>
                            <div className="mb-4" style={{margin:"auto",marginBottom:'5px',color:'#ce7f25'}}><h4>{item.name}</h4></div>
                            <div className="mb-4" style={{margin:"auto",marginBottom:'5px'}}><h6 style={{textAlign:'center'}}>{item.description}</h6></div>
                            {item.price.map((i)=>(
                                <button className={`card-button p-0 mb-2`} style={{fontSize:'20px'}} onClick={()=>RedirectPayment(i)}>${i.unit_amount ? Number((i.unit_amount / 100).toFixed(2)) : 0} <span style={{fontSize:'10px',color:'#F2A64D'}}>usd/{i.recurring.interval}</span></button>
                            ))}
                        </div>
                        ))
                    }
                    </div>
                    <div className="mt-8 flex justify-center">
                            <button className='pay-button p-1 w-1/3 m-auto' onClick={() => {
                                toast('You are successfully registered. Please verify your email.',{type:"success"});
                                setTimeout(() => {
                                    redirect(`/login`)
                                }, 1000)
                                
                            }} style={{fontSize:'16px'}}>Or Continue With Free Plan</button>
                    </div>     
                </div>
                </div>
            </div>
            
        </div>
    )
}