import React, { useEffect, useState, useRef } from "react";
// import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { actions as authActions } from "./../../shared-redux/Auth/slice";
import Header from "../../components/Header";
// import { useNavigate } from "react-router-dom";
import userService from "../../../services/userService";
import {
  Table,
  Input,
  Checkbox,
  DatePicker,
  Loader,
  InputGroup,
  Modal,
  Whisper,
  Button,
  Popover,
} from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import SendIcon from "@rsuite/icons/Send";
import CheckIcon from "@rsuite/icons/Check";
// import { Loader} from 'rsuite';
// import { selectAuth } from './../../shared-redux/Auth/selectors';
import AddColumnModal from "./../../components/AddColumnModal";
import UploadFileModal from "./../../components/UploadFileModal";
import ConfirmationModel from "./../../components/ConfirmationModel";
import PushDataModel from './../../components/PushDataModel'
// import AuthenticatePatientEmailModal from './../../components/AuthenticatePatientEmailModal'
import { ToastContainer, toast } from "react-toastify";
import { redirect } from "../../../utils/response";
import moment from "moment-timezone";
import _ from "lodash";
import CloseIcon from "@rsuite/icons/Close";
import FolderFill from "@rsuite/icons/FolderFill";
import CopyToClipboardButton from "../../components/CopyToClipboardButton";
import MoveDataModel from "../../components/MoveDataModel";

const { Column, Cell, HeaderCell } = Table;

export const DataList = () => {
  const tableRef = useRef();
  const dispatch = useDispatch();

  let lastSkip = 0;
  // const [isUserSubscribed, setIsUserSubscribed] = useState(false)
  const [showBatchProcessingMessage, setshowBatchProcessingMessage] =
    useState(false);
  const [webhooks, setWebhooks] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [patientListInitial, setPatientListInitial] = useState([]);
  const [defaultAvailableColumns, setDefaultAvailableColumns] = useState([]);
  const [uneditable, setUneditable] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  // const [tableKey, setTableKey] = useState(1)

  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showFetchConfirmationModal, setShowFetchConfirmationModal] = useState(false)
  const [showExportConfirmationModal, setShowExportConfirmationModal] = useState(false);
  const [showPushConfirmationModal, setShowPushConfirmationModal] = useState(false);
  const [showPushDataModal, setshowPushDataModal] = useState(false);
  const [showMoveDataModal, setshowMoveDataModal] = useState(false);
  const [editableRow, setEditableRow] = useState({
    row: null,
    column: null,
  });
  const [headers,setHeaders] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelecAll] = useState(false);
  const [totalItem, settotalItem] = useState(0);
  const [tableHeight, seTableHeight] = useState(window.innerHeight);
  const [modalHeight, setModalHeight] = useState(window.innerHeight);
  // const [colSpan ,setColSpan] =useState(1)
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortType, setSortType] = useState("desc");
  const [addedNewRow, setAddedNewRow] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectType, setSelectType] = useState("");
  const [folders, setFolders] = useState([]);
  const [isHovered, setIsHovered] = useState(-1);
  const [isEditing, setIsEditing] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [showAddFolder, setShowAddFolder] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState();
  const [folderToDelete, setFolderToDelete] = useState();
  const [mbiHeader,setMbiHeader] = useState('')
  const [dobHeader,setDobHeader] = useState('')
  const [selectedDateFormat,setSelectedDateFormat] = useState('')
  const [selectedFolderForUpload, setSelectedFolderForUpload] = useState();
  // const [currentEditableDate, setCurrentEditableDate] = useState()
  let curDate = null
  const btnRef = useRef();

  const ChartIcon = ({ size }) => (
    <EditIcon style={{ fontSize: size, marginRight: 10 }} />
  );

    const getProcessStatus = () => {
    dispatch({
      type: authActions.getProcessStatus.type,
      payload:{
        successCallback: (data) => {
          setshowBatchProcessingMessage(data?.status !== "Completed"?true:false)
          if(data?.status === "Completed" && selectedFolder){
            setPatientList([])
            getAllPatients(0)
          }
        }
      }
    })
  }

  useEffect(() => {
    getProcessStatus()
  }, [])
  

  useEffect(() => {
    function updateSize() {
        seTableHeight(getTableHeight());
        setModalHeight(getFolderModalHeight());
    }
    window.addEventListener('resize', updateSize);
  }, [])

  useEffect(() => {
    userService.getDashboardVisible().then((value) => {
      if (value === "false") {
        redirect("/account");
      }
    });
  },[]);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (e.srcElement !== btnRef.current) {
        // setIsOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);

    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);

  const getAllPatients = (
    skipCount = patientList.length,
    orderBy = sortColumn,
    orderDir = sortType,
    searchStr = filterValue
  ) => {
    // setIsCallComplete(false);
    dispatch({
      type: authActions.getAllPatients.type,
      payload: {
        pageSize: 100,
        skipCount: skipCount,
        orderBy,
        orderDir,
        searchStr,
        //loading: skipCount > 0 ? loading : true,
        selectedFolder: selectedFolder.id,
        successCallback: (data) => {
          // setIsCallComplete(true);

          settotalItem(data.totalItem);
          if (data.patientList.length > 0) {
            if (skipCount >= 1) {
              setPatientList([...patientList, ...data.patientList]);
              setPatientListInitial([...patientList, ...data.patientList]);
            } else {
              setPatientList(data.patientList);
              setPatientListInitial(data.patientList);
            }
          } else {
            setPatientList([]);
          }
          setDefaultAvailableColumns(data.defaultAvailableColumns);
          setUneditable(data.uneditable);
          setTableColumn(data.tableColumn);
          // if(skipCount === 0){
          //     setTimeout(() => {
          //         setLoading(false);
          //     },2000)
          // }
          // else
          setLoading(false);
        },
        errorCallback: (error) => {
          console.log("error ==", error);
          setLoading(false);
        },
      },
    });
  };

  const getAllLinks = () => {
    dispatch({
      type: authActions.getAllLinks.type,
      payload:{
        successCallback: (data) => {
          // console.log("all links =", data);
          setWebhooks(data);
        }
      }
    })
  }

  const createLink = (payload) => {
    // setLoading(true)
    dispatch({
      type: authActions.createLink.type,
      payload:{
        data:payload,
        successCallback: (data) => {
          setLoading(false)
          // console.log("created Link =", data);
          getAllLinks()
        }
      }
    })
  }

  const updateLink = (payload, linkId) => {
    dispatch({
      type: authActions.updateLink.type,
      payload:{
        data:payload,
        linkId:linkId,
        successCallback: (data) => {
          // console.log("updated Link =", data);
          getAllLinks()
        }
      }
    })
  }

    const moveFolder = (folder) => {
    dispatch({
      type: authActions.moveLabel.type,
      payload:{
        data:{
          oldLabel:selectedFolder.id,
          newLabel:folder.id,
          patients:selectAll && selectType == "all" ? [] : selected.map((x) => x._id)
        },
        successCallback: (data) => {
          setshowMoveDataModal(false)
          getAllPatients(0)
        }
      }
    })
  }

  const deleteLink = (linkId) => {
    dispatch({
      type: authActions.deleteLink.type,
      payload:{
        linkId:linkId,
        successCallback: (data) => {
          // console.log("delete Link =", data);
          getAllLinks()
        }
      }
    })
  }

  useEffect(() => {
    if (patientList.length >= 1 && selectType == "all") {
      setSelected([...patientList]);
    } else if (patientList.length < 1) {
      setSelectType("");
      setSelected([]);
      setSelecAll(false);
    }
  }, [patientList]);

  const checkSubscription = (value) => {
    try {
      const date = value.createdAt;
      const start = moment(date, "YYYY-MM-DD");
      const end = moment(new Date(), "YYYY-MM-DD");
      const diff = end.diff(start, "days");
      dispatch({
        type: authActions.checkSubscription.type,
        payload: {
          userId: value.id,
          successCallback: (data) => {
            // setIsUserSubscribed(data.subscribed);
            if (data.paymentStatus && data.paymentStatus === "Processing") {
              userService.setDashboardVisible("false");
              redirect("account/1");
              // userService.setDashboardVisible("false")
              // props.updateHeaderCallback("false")
              // setTimeout(() => {
              //     checkSubscription(value);
              // })
            } else if (data.subscriptionDetails) {
              if (
                data?.subscriptionDetails.status === "active" &&
                data.paymentStatus === "Completed" &&
                moment.unix(data?.subscriptionDetails.current_period_end) >=
                  moment(new Date())
              ) {
                userService.setDashboardVisible("true");
              } else {
                if (
                  diff > 30 &&
                  data?.subscriptionDetails.status !== "active"
                ) {
                  //} || moment.unix(data?.subscriptionDetails.current_period_end) < moment(new Date()))){
                  userService.setDashboardVisible("false");
                  redirect("account/1");
                }
              }
            } else {
              if (diff > 30) {
                userService.setDashboardVisible("false");
                redirect("account/1");
                // props.updateHeaderCallback("false")
              } else {
                userService.setDashboardVisible("true");
                // props.updateHeaderCallback("true")
              }
            }
          },
          errorCallback: (error) => {
            // setIsUserSubscribed(false)
            if (diff > 30) {
              userService.setDashboardVisible("false");
              redirect("account/1");
              // props.updateHeaderCallback("false")
            } else {
              userService.setDashboardVisible("true");
              // props.updateHeaderCallback("true")
            }
          },
        },
      });
    } catch (er) {}
  };

  useEffect(() => {
    userService.getUser().then((value) => {
      if (value) {
        checkSubscription(value);
        //setUser(value);
      }
    });
  }, []);

  useEffect(() => {
    getFolderList();
    getAllLinks();
  }, []);

  useEffect(() => {

    // setTimeout(() => {
        if(selectedFolder && selectedFolder.id){
            setPatientList([]);
            setPatientListInitial([])
            setLoading(true);
            getAllPatients(0);
        }
   
    // },1000)
  }, [selectedFolder]);

  useEffect(() => {
    if (addedNewRow) {
      setEditableRow({
        row: 0,
        column: null,
      });
    }
  }, [addedNewRow]);

  useEffect(() => {
    if (editableRow.row !== 0) {
      setAddedNewRow(false);
    }
  }, [editableRow]);

  useEffect(() => {
    if (sortType && sortColumn && selectedFolder && selectedFolder.id) {
      getAllPatients(0);
    }
  }, [sortType, sortColumn]);

  // const onDownload = () => {
  //     dispatch({
  //         type: authActions.downloadSampleCSV.type,
  //         // payload: {
  //         //     data: values,
  //         //     userId,
  //         //     successCallback:() => {
  //         //         redirect('/data-list')
  //         //     }
  //         // }
  //     })
  // }

  const onExport = () => {
    dispatch({
      type: authActions.downloadClientCSV.type,
      payload: {
        selectedFolder: selectedFolder.id
        //   data: values,
        //   userId,
        //   successCallback:() => {
        //       redirect('/data-list')
        //   }
      }
    });
  };

  const onImport = (files, mbiTitle, dobTitle) => {
    // console.log("mbiHeader ==", mbiTitle, "dobHeader ==", dobTitle)
    if (!files || files.length === 0) return;
    const formData = new FormData();
    formData.append("file", files[0].blobFile);
    formData.append('label',selectedFolderForUpload)
    formData.append('mapping',JSON.stringify({'mbi':mbiTitle,'dob':dobTitle}))
    formData.append('dateFormat',selectedDateFormat)
    dispatch({
      type: authActions.importCSV.type,
      payload: {
        data: formData,
        successCallback: () => {
            if(selectedFolder && selectedFolder.id){
                getAllPatients(0);
            }
         
          // redirect('/data-list')
        },
      },
    });
  };

  const onDeleteAllPatient = (rowData, index) => {
    // let patient = {...rowData};
    // delete patient._id;
    dispatch({
      type: authActions.deleteAllPatient.type,
      payload: {
        data: {
          patientIds:
            selectAll && selectType == "all" ? [] : selected.map((x) => x._id),
            label: selectedFolder.id
        },
        successCallback: (value) => {
          setEditableRow({
            row: null,
            column: null,
          });
          setLoading(true);
          // setCurrentPage(1)
          // setPatientList(patientList.filter(x => selected.map(x => x._id).indexOf(x._id) < 0));
          if (
            selected.length === 0 ||
            selected.length === patientList.length ||
            selectAll
          ) {
            setPatientList([]);
          }
          // else {
          setSelecAll(false);
          setSelected([]);
          // setTimeout(() => {
          getAllPatients(0);
          // },500);

          //     setSelected([])
          // }

          // redirect('/data-list')
        },
      },
    });
  };

  const onAddCustom = (column) => {
    dispatch({
      type: authActions.createColumn.type,
      payload: {
        data: {
          column_detail: column,
        },
        successCallback: (value) => {
          getAllPatients(0);
          // setCurrentPage(1)
          // redirect('/data-list')
        },
      },
    });
  };

  const onUpdatePatentData = (key, rowData, index, value, type = "none") => {
    let tempData = [...patientList];
    let tempObj = {
      ...rowData,
      [key]: (key === "mbi") ? value.replaceAll(" ","").replaceAll('-',"") : value,
    };
    tempData[index] = tempObj;
    setPatientList(tempData);
    return tempObj;
    // if(type == "date"){
    //     onUpdatePatient(tempObj,index)
    // }
  };

  const updatePatient = (id, patient, index, rowData) => {
    dispatch({
      type: authActions.updatePatient.type,
      payload: {
        patientId: id,
        data: patient,
        successCallback: (value) => {
          setEditableRow({
            row: null,
            column: null,
          });
          // getAllPatients(0)
          var patients = _.cloneDeep(patientList);
          const updatePatientForList = {
            ...patient,
            dob: moment(patient.dob ? patient.dob.split('T')[0]: value.dob.split('T')[0]).format("MM-DD-yyyy") // moment(patient.dob).format("MM-DD-yyyy")
          } 
          patients.splice(index, 1, { ...rowData, ...updatePatientForList });
          setPatientList(patients);
          setPatientListInitial(patients);
          setAddedNewRow(false);
        },
      },
    });
  };

  const handleKeyDown = (event, rowData, index) => {
    if ((event.key === "Enter" || event.type === "blur") && (rowData.mbi && rowData.dob)) {
      onUpdatePatient(rowData, index);
    }
  };

  const onUpdatePatient = (rowData, index) => {
    if (!rowData._id) {
      onAddPatient(rowData, index);
      return;
    }
    let patient = { ...rowData };
    delete patient._id;
    delete patient.plan;
    if (!patient.dob) {
      toast("Enter Date of birth!", { type: "error", autoClose: 7000 });
      return;
    }
    if (!patient.mbi) {
      toast("Enter MBI!", { type: "error", autoClose: 7000 });
      return;
    }
    updatePatient(rowData._id, patient, index, rowData);
    curDate = null
  };

  // const onDeletePatient = (rowData, index) => {
  //     // let patient = {...rowData};
  //     // delete patient._id;
  //     dispatch({
  //         type: authActions.deletePatient.type,
  //         payload: {
  //             patientId:rowData._id,
  //             // data: patient,
  //             successCallback:(value) => {
  //                 setEditableRow({
  //     row:null,
  //     column:null
  // })
  //                 // setCurrentPage(1)
  //                 // setPatientList([])
  //                 getAllPatients(0)
  //                 // redirect('/data-list')
  //             }
  //         }
  //     })
  // }

  const addRow = (index) => {
    let list = [...patientList];
    let emptyObj = tableColumn.reduce(
      (o, key) => ({ ...o, [key.column_name]: "" }),
      {}
    );
    list.splice(index, 0, emptyObj);
    setPatientList(list);
    // setEditableRow(index+1)
  };

  const clean = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const onAddPatient = (rowData, index) => {
    let patient = { ...rowData };
    if (!patient.dob) {
      toast("Enter Date of birth!", { type: "error", autoClose: 7000 });
      return;
    }
    if (!patient.mbi) {
      toast("Enter MBI!", { type: "error", autoClose: 7000 });
      return;
    }
    // delete patient._id;
    dispatch({
      type: authActions.addPatient.type,
      payload: {
        //patientId:rowData._id,
        data: clean({...patient, label:selectedFolder.id}),
        successCallback: (value) => {
          setEditableRow({
            row: null,
            column: null,
          });
          // setCurrentPage(1)
          setPatientList([]);
          getAllPatients(0);
          // redirect('/data-list')
        },
      },
    });
  };

  // const onDiscard = (rowData, index) => {
  //     setEditableRow(null);
  //     //check for temp row
  //     if(!rowData._id){
  //         let list = [...patientList];
  //         list.splice(index, 1)
  //         setPatientList(list);
  //     }else{
  //         setPatientList(patientListInitial);
  //     }
  // }

  // const FixedLoader = () => (
  //     <Loader
  //       content="Loading..."
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         position: 'absolute',
  //         bottom: '0',
  //         background: '#f5f5f5',
  //         width: '100%',
  //         padding: '4px 0'
  //       }}
  //     />
  //   );

  //   const LoadMore = () => {

  //     return(
  //         <div className=" flex justify-end mt-2 align-center text-sm py-2">
  //             {`Displaying ${patientList.length} out of toal ${totalItem} records `}
  //             {
  //                 (patientList.length > 0 && patientList.length < totalItem) && (
  //                     <div onClick={() => {loadMore()}} className="z-0 font-bold px-2  rounded-md text-orange-200 text-sm underline cursor-pointer">Load more</div>
  //                 )
  //             }

  //         </div>

  //     )
  //   }

  const loadMore = () => {
    //   if(loading) return;
    //
    if (patientList.length < totalItem) {
      // if(patientList.length > 0)
      //     setLoading(false)
      // else
      setLoading(true);
      getAllPatients(patientList.length);
    }
  };

  // const handleScroll = (x, y) => {

  //     const contextHeight = patientList.length * 96;
  //     const top = Math.abs(y);
  //     console.log('val =', contextHeight - top - (window.screen.height-(window.screen.height * .4)), ((window.screen.height-(window.screen.height * .4))-100) )
  //     if (contextHeight - top - (window.screen.height-(window.screen.height * .4)) < ((window.screen.height-(window.screen.height * .4))-100)) {
  //         // if(lastCall === top || lastCallSkip === patientList.length || loading) return;
  //         // setLasCallSkip(patientList.length)
  //         // setLasCall(top);
  //         loadMore();
  //     }
  // }

  // const btnColor = {
  //     Authenticated : 'bg-green-200',
  //     Unauthenticated: 'bg-gradient-to-b from-orange-200 to-orange-500',
  //     Pending: 'bg-gray-400'
  // }

  // const onGroupAuthenticateOld = () => {
  //     if(selected.length < 1){
  //         toast("No patient record selected to authenticate!",{type:"error"});
  //         return;
  //     }
  //     const selectPatients = selected.map(x => ({
  //         patientId: x._id,
  //         email : (x.email) ? x.email : ""
  //     }))
  //     authenticate("email", selectPatients);
  // }

  const onFetchClick = () => {
    if (selected.length < 1) {
      toast("Please select records.", { type: "error", autoClose: 7000 });
      return;
    }
    // if(selected.length > 50){
    //     toast("Please select maximum 100 records to fetch.",{type:"error"});
    //     return;
    // }
    setShowFetchConfirmationModal(true);
  };

  const onBatchProcessing = () => {
    const selectPatients = selected.map((x) => x._id);
    let data = {};
    if (selectType == "visible") {
      data.patients = selectPatients;
    }else{
      data = {
        label: selectedFolder.id
      }
    }
    dispatch({
      type: authActions.fetchBatch.type,
      payload: {
        data: data,
        successCallback: (value) => {
          // console.log("fettch batch ==", value)
          // setshowBatchProcessingMessage(true);
         
          setSelecAll(false);
          setSelected([]);
          setEditableRow({
            row: null,
            column: null,
          });
          getProcessStatus()
          // setCurrentPage(1)
          // if(value && value.data){
          //     window.open(value.data, '_blank')
          // }
          // setPatientList([])
          // getAllPatients(0)
          // redirect('/data-list')
        },
      },
    });
  };

  const onGroupAuthenticate = () => {
    if (selected.length < 1) {
      toast("Please select records.", { type: "error", autoClose: 7000 });
      return;
    }
    if (selected.length > 100) {
      toast("Please select maximum 100 records to fetch.", {
        type: "error",
        autoClose: 7000,
      });
      return;
    }
    const selectPatients = selected.map((x) => x._id);
    dispatch({
      type: authActions.authenticatePatients.type,
      payload: {
        data: {
          patients: selectPatients,
        },
        successCallback: (value) => {
          setSelecAll(false);
          setSelected([]);
          setEditableRow({
            row: null,
            column: null,
          });
          // setCurrentPage(1)
          // if(value && value.data){
          //     window.open(value.data, '_blank')
          // }
          setPatientList([]);
          getAllPatients(0);
          // redirect('/data-list')
        },
      },
    });
  };

  // const authenticate = (choice, users) => {
  //     dispatch({
  //         type: authActions.bbAuthorize.type,
  //         payload: {
  //             data: {
  //                 choice,
  //                 users
  //             },
  //             successCallback:(value) => {
  //                 setSelected([])
  //                 setEditableRow(null)
  //                 // setCurrentPage(1)
  //                 if(value && value.data){
  //                     window.open(value.data, '_blank')
  //                 }
  //                 setPatientList([])
  //                 getAllPatients(0)
  //                 // redirect('/data-list')
  //             }
  //         }
  //     })
  // }

  // const onRowAuthenticate = (rowData, choice, email = "") => {
  //     if(rowData.bb_auth_status !== "Unauthenticated") return;
  //     const selectPatients = {
  //         patientId: rowData._id,
  //         email : (email && email.length > 0) ? email : (rowData.email) ? rowData.email : ""
  //     }
  //     authenticate(choice, [selectPatients]);
  // }

  const onRowCheckUncheck = (rowData, index, checked) => {
    if (checked) {
      setSelected([...selected, rowData]);
      if ([...selected, rowData].length === patientList.length) {
        setSelecAll(true);
      }
    } else {
      setSelected([...selected].filter((x) => x._id !== rowData._id));
      setSelecAll(false);
    }
  };

  // const getSelectedRowValue = (rowData) => {
  //     if(selected.indexOf(x => x._id === rowData._id) < 0)
  //         return false;
  //     return  true;
  // }

  const onReadUnread = (rowData, data, index) => {
    updatePatient(rowData._id, data, index, rowData);
  };

  const handleScroll = (x, y) => {
    if (y == 0) {
      return;
    }
    const contextHeight = patientList.length * 60;
    const top = Math.abs(y);
    const tHeight = getTableHeight();
    // console.log("y==",y," getTableHeight ==", tHeight, " contextHeight =", contextHeight)
    if (
      contextHeight - top - tHeight < tHeight - 200 &&
      !loading &&
      lastSkip !== patientList.length
    ) {
      lastSkip = patientList.length;
      loadMore();
    }
    // if (contextHeight - top - tableHeight < tableHeight-100 && contextHeight - top - tableHeight > tableHeight-150) {
    //     setLoad(true);
    //   loadData();
    // }
  };

  //     const FixedLoader = () => (
  //   <Loader
  //     content="Loading..."
  //     style={{
  //       display: 'flex',
  //       justifyContent: 'center',
  //       position: 'absolute',
  //       bottom: '0',
  //       background: '#f5f5f5',
  //       width: '100%',
  //       padding: '4px 0'
  //     }}
  //   />
  // )

  const getTableHeight = () => {
    try {
      const batchMessage = document.getElementById("batch").offsetHeight;
      const folderDiv = document.getElementById("folderDiv").offsetHeight;
      const message = document.getElementById("message").offsetHeight;
      const headerHeight = document.getElementById("headerID").offsetHeight;
      const dataListOptions =
        document.getElementById("dataListOptions").offsetHeight;
      const searchDiv = document.getElementById("searchDiv").offsetHeight;
      const windowHeight = window.innerHeight;
      return (
        windowHeight -
        (headerHeight + searchDiv + dataListOptions + batchMessage+ message)
      );
      // if(showFolderModal){
      //   return (
      //       windowHeight -
      //       (headerHeight + searchDiv + dataListOptions + batchMessage)
      //     );
      // }else{
      //   return (
      //       windowHeight -
      //       (headerHeight + searchDiv + dataListOptions + batchMessage)
      //     );
      // }
     
    } catch (e) {
      return window.innerHeight;
    }
  };

  const getFolderModalHeight = () => {
    try {
      const batchMessage = document.getElementById("batch").offsetHeight;
      const message = document.getElementById('message').offsetHeight;
      const headerHeight = document.getElementById("headerID").offsetHeight;
      // const dataListOptions = document.getElementById('dataListOptions').offsetHeight;
      // const searchDiv = document.getElementById('searchDiv').offsetHeight;
      const windowHeight = window.innerHeight;
      return windowHeight - (headerHeight + batchMessage+message);
    } catch (e) {
      return window.innerHeight;
    }
  };

  useEffect(() => {
    seTableHeight(getTableHeight());
    setModalHeight(getFolderModalHeight());
  }, [showBatchProcessingMessage, showFolderModal, window.innerHeight, totalItem]);

  const editData = (column, index) => {
    setEditableRow({ row: index, column: column?.position });
    // document.getElementById(`${column.column_name}-${index}`).focus()
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setSortType(sortType);
    setSortColumn(sortColumn);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    setFilterValue("");
    getAllPatients(0, sortColumn, sortType, "");
    setSelectType("");
    setSelected([]);
    setSelecAll(false);
  };

  const searchResult = (e) => {
    e.preventDefault();
    getAllPatients(0);
    setSelectType("");
    setSelected([]);
    setSelecAll(false);
  };

//   useEffect(() => {
//     seTableHeight(getTableHeight());
//   }, [window.innerHeight, totalItem]);

  const selectMethod = () => {
    setSelected([...patientList]);
    setSelecAll(true);
  };

  const toggleSelectAllBox = (checked) => {
    if (!checked) {
      setIsOpen(false);
      setSelectType("");
      setSelected([]);
      setSelecAll(false);
    } else if (checked) {
      setIsOpen(true);
    }
  };

  const getFolderList = () => {
    setShowAddFolder(true);
    dispatch({
      type: authActions.getAllFolders.type,
      payload: {
        successCallback: (data) => {
          // setIsCallComplete(true);

          // console.log("folders ==", data);
          setFolders(data);
          if(data.length > 0 && (!selectedFolder || !selectedFolder.id)){
            setSelectedFolder(data[0])
          }
          setLoading(false);
        },
        errorCallback: (error) => {
          console.log("error ==", error);
          setLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    if(tableColumn){
      const arrayOfHeaders = tableColumn.map(obj => obj.column_name);
      setHeaders(arrayOfHeaders)
    }else{
      setHeaders([])
    }
  }, [tableColumn])

  const deleteFolder = () => {
        if(!folderToDelete) return;
        dispatch({
            type: authActions.deleteFolder.type,
            payload: {
              labelId: folderToDelete.id,
              successCallback: (data) => {
                setIsEditing(-1);
                if(folderToDelete.id === selectedFolder.id){
                  setSelectedFolder(folders[0]);
                }
                getFolderList();
                setLoading(false);
              },
              errorCallback: (error) => {
                // console.log("error ==", error);
                setLoading(false);
              },
            },
          });
  }

  const addNewFolder = () => {
    setFolderName('')
    setShowAddFolder(false);
    let tempFolders = folders;
    tempFolders.push({ label: "" });
    setFolders(tempFolders);
    setIsEditing(tempFolders.length - 1);
  };

  const updateFolder = (folder) => {
    if (folderName.length > 0) {
      dispatch({
        type: authActions.updateFolder.type,
        payload: {
          labelId: folder.id,
          data: {
            label: folderName,
          },
          successCallback: (data) => {
            setIsEditing(-1);
            getFolderList();
            setLoading(false);
          },
          errorCallback: (error) => {
            console.log("error ==", error);
            setLoading(false);
          },
        },
      });
    }
  };

  const addExtraFolder = () => {
    if (folderName.length > 0) {
      dispatch({
        type: authActions.addFolder.type,
        payload: {
          data: {
            label: folderName,
          },
          successCallback: (data) => {
            setIsEditing(-1);
            getFolderList();
            setLoading(false);
          },
          errorCallback: (error) => {
            console.log("error ==", error);
            setLoading(false);
          },
        },
      });
    }
  };

  const speaker = (url) => {
    return (
      <Popover style={{ minWidth: 200 , maxWidth:410}}>
        <p className="font-bold">Webhook url destination:</p>
        <div className="flex justify-between gap-x-1 pr-1">
          <p className="font-bold flex flex-wrap break-word">{url}</p>
          <CopyToClipboardButton textToCopy={url} />
        </div>
      </Popover>
    )
  }

  const bulkExportToCRM = (webhooks) => {

    let dataToPush= (selectAll && selectType === "all") ?
    {
      label: selectedFolder.id ,
      exportLink: webhooks.map(x => x.exportLink)
    }
     :
    {
      patients: selected.map(x => x._id),
      exportLink: webhooks.map(x => x.exportLink)
    }
    dispatch({
      type: authActions.bulkExportToCRM.type,
      payload: {
        data: dataToPush,
        successCallback: (data) => {
          setshowPushDataModal(false)
          setLoading(false);
        },
        errorCallback: (error) => {
          console.log("error ==", error);
          setLoading(false);
        },
      },
    });
  }
  
  const onPushToWebhooks = (webhooks) => {
    setLoading(true)
    // console.log("selected hooks =", webhooks)

    if((selectAll && selected.length > 50) || selected.length > 50){
      bulkExportToCRM(webhooks);
      return;
    }

    let dataToPush={
      patients: selected.map(x => x._id),
      exportLink: webhooks.map(x => x.exportLink)
    }
    dispatch({
      type: authActions.exportToCRM.type,
      payload: {
        data: dataToPush,
        successCallback: (data) => {
          setshowPushDataModal(false)
          setLoading(false);
        },
        errorCallback: (error) => {
          console.log("error ==", error);
          setLoading(false);
        },
      },
    });
  }

  return (
    <div className="font-Poppins bg-gradient-to-r from-white from-40% via-green-300 via-60% to-white w-full h-screen overflow-scroll">
      {/* {
                (!isUserSubscribed && totalItem > 10) ? (
                    <div id="message" className="bg-orange-100 p-3 justify-center flex">{`You have access to only 10 beneficiary records in free plan. Please`}<div><b className="cursor-pointer px-1" onClick={() => { redirect('/account/1')}}>{` upgrade `}</b></div>{` to pro plan to access all beneficiary records.`}</div>
                )
                :  <div id="message" />
            } */}
            <b className="cursor-pointer" onClick={() => {
                        window.open(`https://medizues.com/news`, '_blank')
                    }}>
                    <div id="message" className="bg-orange-100 p-3 justify-center flex">Service has been temporarily paused. Please refer to the letter by our founder here.</div></b>
            

      {showBatchProcessingMessage ? (
        <div
          id="batch"
          className="bg-orange-100 p-3 justify-center text-center flex"
        >Fetch Data is now in progress. Estimated duration: 15 minutes for every 2,000 rows.<br/>
        An email notification will be sent upon completion of your batch. Feel free to close this tab. Refresh the page to view the updated data.</div>
      ) : (
        <div id="batch" />
      )}

      <Header active={1} />
      {/* <div className="w-full p-8 flex pl-16">
                <img src="/images/logo.png" className="h-16" />
            </div> */}
      <div className="flex">
        <div className={`transition-all ${showFolderModal ? "duration-500 w-3/12" : "duration-100 w-0"}`}>
          {showFolderModal && (
            <div
              className="bg-green-200 w-full rounded-3xl shadow-lg"
              style={{ height: modalHeight }}
            >
              <div className="flex justify-between items-center p-2">
                <div/>
                <div className="text-lg text-white font-bold">Folders</div>
                <div
                  id="folderDiv"
                  onClick={() => setShowFolderModal(!showFolderModal)}
                  className="cursor-pointer pb-1 bg-green-200 ml-5 flex justify-center p-1 rounded-xl gap-x-1 items-center"
                >
                  <FolderFill color="#ffffff" size="4em" />
                  <div className="text-white mt-1">{"<"}</div>
                </div>
              </div>
              <div className="gap-y-2 px-3">
                {folders.map((folder, index) => (
                  <div
                    onClick={() => setSelectedFolder(folder)}
                    className={`mt-1 flex justify-between w-full bg-green-700 p-2 rounded-md text-white cursor-pointer ${selectedFolder?.id === folder?.id ? 'border border-white' : 'border-none'}`}
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(-1)}
                  >
                    <div />
                    {/* <div className="self-center"> */}
                      {isEditing == index ? (
                        <Input
                          className="text-center bg-transparent text-white"
                          onChange={(e) => setFolderName(e)}
                          value={folderName}
                        ></Input>
                      ) : (
                        <span className="ml-0">{folder.label}</span>
                      )}
                    {/* </div> */}
                    <div className="flex justify-end">
                      {/* {isHovered == index && isEditing == -1 && ( */}
                        <div className={`flex ${(isHovered == index && isEditing == -1) ? 'visible' : 'invisible'}`}>
                          <Whisper
                            onClick={(e) => e.stopPropagation()}
                            preventOverflow
                            trigger="click"
                            controlId="control-id-container"
                            speaker={speaker(folder.dataUrl ? folder.dataUrl : '')}
                            placement="auto"
                          >
                            <button>
                              <SendIcon
                                style={{ fontSize: 16, marginRight: 0 }}
                              />
                            </button>
                          </Whisper>
                          <div
                            className={`ml-3 mr-2 cursor-pointer`}
                            onClick={(e) => {
                                e.stopPropagation()
                              setIsEditing(index);
                              setFolderName(folder.label);
                            }}
                          >
                            {" "}
                            <EditIcon
                              style={{ fontSize: 16, marginRight: 0 }}
                            />
                          </div>
                        </div>
                      {/* )} */}
                      {/* {isEditing == index && ( */}
                        <div className={`flex ${isEditing == index ? 'visible' : 'hidden'}`}>
                         
                          <button
                            className="mx-1"
                            onClick={(e) =>{
                                e.stopPropagation()
                                if(showAddFolder)
                                    updateFolder(folder)
                                else addExtraFolder()
                            }}
                          >
                            <CheckIcon
                              style={{ fontSize: 14, marginRight: 3 }}
                            />
                          </button>
                          <button
                            onClick={(e) => {
                                e.stopPropagation()
                                if(!folder || !folder.id){
                                  setIsEditing(-1);
                                  getFolderList();
                                  return;
                                }
                                setFolderToDelete(folder)
                                setIsModalOpen(true);
                            }}
                          >
                            <CloseIcon
                              style={{ fontSize: 14, marginRight: 0 }}
                            />
                          </button>
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                ))}
                {showAddFolder && (
                  <div
                    onClick={() => addNewFolder()}
                    className="flex justify-center text-white mt-2 cursor-pointer"
                  >{`+ Add more`}</div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`transition-all ${showFolderModal ? "duration-500 w-9/12" : "duration-100 w-full"}`}>
          <div id="dataListOptions" className="pr-5 lg:pr-5 md:pr-5 pb-6 pl-5">
            <div className="flex flex-wrap mt-0 justify-between w-full gap-y-2">
              <div className="flex flex-wrap gap-x-3 gap-y-2">
              {!showFolderModal && (
                <div
                  id="folderDiv"
                  onClick={() => setShowFolderModal(!showFolderModal)}
                  className="cursor-pointer pb-1 bg-green-200 w-10 flex justify-center p-1 rounded-xl gap-x-1 items-center"
                >
                  <FolderFill color="#ffffff" size="2em" />
                  <div className="text-white mt-1">{">"}</div>
                </div>
              )}
              <div
                onClick={() => onFetchClick()}
                className="flex items-center gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-green-200 text-sm border border-solid border-green-200"
              >
                <img alt="" className="h-5" src="/images/playCircle.png" />
                {`Fetch Data`}
              </div>
              </div>
              <div className="flex flex-wrap mt-0 gap-y-2 gap-x-3">
                {/* <div onClick={() => onDownload()} 
                            className="flex gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200">
                            <img className="h-4" src="/images/icon_download.png"  />
                            Download
                        </div> */}
                <div
                  onClick={() => {
                    if (patientList.length > 0) {
                      setShowConfirmationModal(true);
                    }
                  }}
                  className={`flex gap-x-1 font-bold ${
                    patientList.length > 0 ? "cursor-pointer" : ""
                  } bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200`}
                >
                  <img alt="" className="h-4" src="/images/trash.png" />
                  {/* Clear Records */}
                </div>
                <div
                  onClick={() => {
                    if(folders.length < 1){
                        toast("Please create a folder to add records!",{type:"error"});
                        return;
                    }
                    addRow(0);
                    setEditableRow({
                      row: 0,
                      column: null,
                    });
                    setAddedNewRow(true);
                  }}
                  className="flex gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200"
                >
                  <img alt="" className="h-4" src="/images/add_row.png" />
                  Add Row
                </div>
                <div
                  onClick={() => {
                    if(selected.length < 1){
                      toast("Please select records.", { type: "error", autoClose: 7000 });
                      return;
                    }
                    setshowMoveDataModal(true)
                  }}
                  className="flex gap-x-1 font-bold font-Poppins cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200"
                >
                   <img alt="" className="h-4" src="/images/move.png" />
                  Move
                </div>
                <div
                  onClick={() => {
                    if(selected.length < 1){
                      toast("Please select records.", { type: "error", autoClose: 7000 });
                      return;
                    }
                    // if((selectAll && selected.length > 50) || selected.length > 50){
                    //   toast("Please select maximum 50 records.", { type: "error", autoClose: 7000 });
                    //   return
                    // }
                    setShowPushConfirmationModal(true)
                  }}
                  className="flex gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200"
                >
                  <img alt="" className="h-4" src="/images/icon_export.png" />
                  Push
                </div>
                <div
                //   onClick={() => onExport()}
                  onClick={() => setShowExportConfirmationModal(true)}
                  className="flex items-center gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200"
                >
                  <img alt="" className="h-4" src="/images/icon_import_inverted.png" />
                  Export
                </div>
               
                {/* <input type="file" id="myFile" name="filename" className="hidden" accept=".csv" onChange={(e) => {onImport(e.target.files);e.target.value = ''}}></input> */}
                <div
                  onClick={() => {
                    // if(!isUserSubscribed && patientList.length>=10){
                    //     toast("Upgrade to Pro plan to add more data!",{type:"error"});
                    //     return;
                    // }
                    setSelectedFolderForUpload(selectedFolder?.id)
                    setMbiHeader('')
                    setDobHeader('')
                    setSelectedDateFormat('')
                    setShowUploadModal(true);
                  }}
                  className="flex gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200"
                >
                  <img alt="" className="h-4" src="/images/icon_import.png" />
                  Import
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center">
            <div className="px-3 lg:px-16 md:px-16 pb-4">
              <div className="flex flex-col">
                <span className="text-sm text-gray-400">
                  {" "}
                  Row Count: {totalItem}{" "}
                </span>
                <span className="text-sm text-gray-400">
                  {" "}
                  Selected:{" "}
                  {selectAll && selectType == "all"
                    ? totalItem
                    : selected?.length}{" "}
                </span>
              </div>
            </div>
            <div id="searchDiv" className="px-3 lg:px-16 md:px-16 pb-3 flex">
              <InputGroup className="w-96">
                <Input
                  placeholder="Search MBIs, Names, Contracts and Plan Names"
                  value={filterValue}
                  onChange={(val) => setFilterValue(val)}
                />
                <InputGroup.Button onClick={(e) => clearFilter(e)}>
                  <CloseIcon />
                </InputGroup.Button>
              </InputGroup>
              <div
                style={{ marginLeft: "15px" }}
                onClick={(e) => searchResult(e)}
                className="flex gap-x-1 font-bold cursor-pointer bg-white px-8 py-2 rounded-md text-orange-200 text-sm border border-solid border-orange-200"
              >
                Search
              </div>
            </div>
          </div>
          <div id="tableContainer" className=" relative">
            <Table
              wordWrap="break-word"
              className="rounded-md bg-white"
              // rowClassName="rs-table-row-custom hover:bg-green-300"
              // bordered={true}
              showHeader={true}
              ref={tableRef}
              affixHorizontalScrollbar
              shouldUpdateScroll={false}
              locale={{ emptyMessage: "" }}
              // autoHeight
              data={patientList}
              rowHeight={60}
              height={tableHeight}
              // height={tableHeight}
              headerHeight={50}
              // virtualized
              onScroll={handleScroll}
              rowClassName={(rowData, index) =>
                rowData && rowData["marked"]
                  ? "read-bg overflow-visible"
                  : "overflow-visible"
              }
              loading={loading}
              onSortColumn={handleSortColumn}
              sortColumn={sortColumn}
              sortType={sortType}
            >
              <Column
                fixed="left"
                align="center"
                key="data1"
                width={60}
                resizable
              >
                <HeaderCell>
                  <div className="relative">
                    <Checkbox
                      ref={btnRef}
                      // type="success"
                      checked={selectAll}
                      className="accent-green-200"
                      name="selectAll"
                      onChange={(value, checked) => {
                        toggleSelectAllBox(checked);
                      }}
                      id="selectAll"
                    />
                    <ul
                      className={`${
                        isOpen ? "block" : "hidden"
                      } bg-white text-gray-200 pt-1 custom-dropdown`}
                    >
                      <li
                        className="py-2 px-4 set-option"
                        onClick={() => {
                          setSelectType("all");
                          setIsOpen(false);
                          selectMethod();
                        }}
                      >
                        Select All
                      </li>
                      <li
                        className="py-2 px-4 set-option"
                        onClick={() => {
                          setSelectType("visible");
                          setIsOpen(false);
                          selectMethod();
                        }}
                      >
                        Select Visible
                      </li>
                    </ul>
                  </div>
                </HeaderCell>
                <Cell dataKey="select">
                  {(rowData, index) => {
                    return (
                      <Checkbox
                        type="success"
                        className="accent-green-200"
                        // disabled={(rowData["bb_auth_status"] === "Unauthenticated" ) ? false : true}
                        // defaultChecked={(rowData["bb_auth_status"] === "Authenticated" ) ? true : false}
                        name="select"
                        onChange={(value, checked) => {
                          onRowCheckUncheck(rowData, index, checked);
                        }}
                        checked={
                          selected.filter((x) => x._id === rowData._id).length >
                          0
                            ? true
                            : false
                        }
                        id="select"
                      />
                    );
                  }}
                </Cell>
              </Column>
              {/* <Column align="center" key="data1" width={150} key='status' resizable>
                        <HeaderCell>Status</HeaderCell>
                        <Cell dataKey="status">
                            {
                                (rowData, index) => {
                                    return <div onClick={() => {}} className="font-bold cursor-pointer bg-green-200 px-2 py-2 rounded-md text-white text-xs">{`Authenticated`}</div>
                                }
                            }
                        </Cell> 
                    </Column> */}
              {tableColumn
                .filter((x) => x.column_name !== "status")
                .map((column, index) => {
                  return (
                    <Column
                      align="center"
                      width={170}
                      key={column.column_name}
                      resizable
                      sortable={column.column_name !== "marked"}
                    >
                      <HeaderCell>{column.label}</HeaderCell>
                      <Cell
                        dataKey={column.column_name}
                        className={
                          uneditable.indexOf(column.column_name) === -1
                            ? "bob"
                            : ""
                        }
                      >
                        {(rowData, index) => {
                          return (
                            <div className="flex justify-center w-full">
                              {rowData.humana_error &&
                              rowData.humana_error.detail &&
                              column.column_name === "contract_number" ? (
                                <div
                                  className={`${
                                    rowData["marked"] === false
                                      ? "text-red-400"
                                      : ""
                                  }`}
                                >
                                  {rowData.humana_error.detail}
                                </div>
                              ) : column.column_name === "marked" ? (
                                <div className="flex justify-center">
                                  {rowData[column.column_name] ? (
                                    <img
                                      alt=""
                                      onClick={() => {
                                        if (rowData._id) {
                                          onReadUnread(
                                            { ...rowData },
                                            { marked: false },
                                            index
                                          );
                                        }
                                      }}
                                      className="h-5 px-5 cursor-pointer object-contain"
                                      src="/images/read.png"
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      onClick={() => {
                                        if (rowData._id) {
                                          onReadUnread(
                                            { ...rowData },
                                            { marked: true },
                                            index
                                          );
                                        }
                                      }}
                                      className="h-4 px-5 cursor-pointer"
                                      src="/images/unread.png"
                                    />
                                  )}
                                </div>
                              ) : uneditable.indexOf(column.column_name) !==
                                -1 ? (
                                <div
                                  className={`${
                                    rowData["marked"] === false
                                      ? "text-neutral-400"
                                      : ""
                                  }`}
                                >
                                  {
                                    (column.column_name === "contract_number" && rowData[column.column_name] && rowData["hyperlink"])
                                    ?
                                      (<div className="text-green-400 underline cursor-pointer" onClick={() => window.open(rowData["hyperlink"],"_blank")}>{rowData[column.column_name]}</div>)
                                    :
                                    (column.column_name === "future_contract_number" && rowData[column.column_name] && rowData["future_hyperlink"]) ?
                                    (
                                      (<div className="text-green-400 underline cursor-pointer" onClick={() => window.open(rowData["future_hyperlink"],"_blank")}>{rowData[column.column_name]}</div>)
                                    )
                                    :

                                  (column.column_name === "plan" || column.column_name === "future_plan") && rowData[column.column_name] && rowData[column.column_name].plan_name
                                    ? rowData[column.column_name].plan_name
                                    : 
                                    column.data_type !== "date"
                                      ? ( rowData[column.column_name]
                                        ? rowData[column.column_name]
                                        : "")
                                      : ((rowData[column.column_name] && rowData[column.column_name].indexOf('T') !== -1)
                                          ? moment(rowData[column.column_name]).format("MM-DD-yyyy HH:mm A")
                                          : rowData[column.column_name]
                                        )
                                  }
                                </div>
                              ) : (
                                <div>
                                  {editableRow.row === index &&
                                  editableRow.column === column.position &&
                                  !addedNewRow ? (
                                    <>
                                      {column.data_type === "date" ? (
                                        <DatePicker
                                          editable
                                          placeholder="Select Date"
                                          format={
                                            column.column_name === "dob"
                                              ? "MM-dd-yyyy"
                                              : ""
                                          }
                                          defaultValue={
                                            rowData[column.column_name]
                                              ? new Date(
                                                  rowData[column.column_name]
                                                )
                                              : null
                                          }
                                          onClean={(value) => {
                                            curDate =''
                                            onUpdatePatentData(
                                              column.column_name,
                                              rowData,
                                              index,
                                              "",
                                              "date"
                                            );
                                          }}
                                          onChangeCalendarDate={(value) => {
                                            
                                            curDate = `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`
                                            // console.log("onChangeCalendarDate =", curDate)
                                            // onUpdatePatentData(
                                            //   column.column_name,
                                            //   rowData,
                                            //   index,
                                            //   value,
                                            //   "date"
                                            // );
                                          }}
                                          onChange={(value) => {
                                            // console.log("onChange =", curDate)
                                            curDate = `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`
                                            // onUpdatePatentData(
                                            //   column.column_name,
                                            //   rowData,
                                            //   index,
                                            //   value,
                                            //   "date"
                                            // );
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.type === "blur"){
                                              //  console.log("onKeyDown =", curDate)
                                               const updatedObj = onUpdatePatentData(
                                                  column.column_name,
                                                  rowData,
                                                  index,
                                                  curDate,
                                                  "date"
                                                );
                                                handleKeyDown(e, updatedObj, index);
                                            }
                                          }}
                                          onOk={(value, e) => {
                                            const updatedObj = onUpdatePatentData(
                                              column.column_name,
                                              rowData,
                                              index,
                                              `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`,
                                              "date"
                                            );
                                            if (value === null || !rowData.mbi) return;
                                            onUpdatePatient(updatedObj, index);
                                            // onUpdatePatentData(column.column_name, rowData, index, value,"date")
                                          }}
                                        />
                                      ) : (
                                        <Input
                                          id={`${column.column_name}-${index}`}
                                          defaultValue={rowData[column.column_name]}
                                          onKeyDown={(e) => {
                                            handleKeyDown(e, rowData, index);
                                          }}
                                          onBlur={(e) => {
                                            // console.log("on blur 1664 =",e.type)
                                            handleKeyDown(e, rowData, index);
                                            setEditableRow({
                                              row: null,
                                              column: null,
                                            });
                                          }}
                                          onChange={(value, e) => {
                                            onUpdatePatentData(
                                              column.column_name,
                                              rowData,
                                              index,
                                              value
                                            );
                                          }}
                                        />
                                      )}
                                    </>
                                  ) : editableRow.row === index &&
                                    addedNewRow ? (
                                    <>
                                      {column.data_type === "date" ? (
                                        <DatePicker
                                          placeholder="Select Date"
                                          format={
                                            column.column_name === "dob"
                                              ? "MM-dd-yyyy"
                                              : ""
                                          }
                                          defaultValue={
                                            rowData[column.column_name]
                                              ? new Date(
                                                  rowData[column.column_name]
                                                )
                                              : null
                                          }
                                          // value={curDate ? curDate : (
                                          //   rowData[column.column_name]
                                          //     ? new Date(
                                          //         rowData[column.column_name]
                                          //       )
                                          //     : null
                                          // )}
                                          onClean={(value) => {
                                            curDate =''
                                            onUpdatePatentData(
                                              column.column_name,
                                              rowData,
                                              0,
                                              "",
                                              "date"
                                            );
                                          }}
                                          onChangeCalendarDate={(value, event) => {
                                            // console.log("onChangeCalendarDate =", event)
                                            curDate = `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`
                                            // setCurrentEditableDate(value)
                                            // onUpdatePatentData(
                                            //   column.column_name,
                                            //   rowData,
                                            //   0,
                                            //   value,
                                            //   "date"
                                            // );
                                          }}
                                          onChange={(value, e) => {
                                            // console.log("onChange =", value)
                                            curDate = `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`
                                            // onUpdatePatentData(
                                            //   column.column_name,
                                            //   rowData,
                                            //   0,
                                            //   value,
                                            //   "date"
                                            // );
                                          }}
                                          onKeyDown={(event) => {
                                            // console.log("onKeyDown =", event)
                                            if (event.key === "Enter" || event.type === "blur"){
                                              // console.log("onKeyDown =", curDate)
                                              const updatedObj = onUpdatePatentData(
                                                column.column_name,
                                                rowData,
                                                0,
                                                curDate,
                                                "date"
                                              );
                                              handleKeyDown(event, updatedObj, index);
                                            }
                                            
                                          }}
                                          onOk={(value, e) => {
                                            // console.log("onOk =", value, "curdate=",curDate)
                                            const updatedObj = onUpdatePatentData(
                                              column.column_name,
                                              rowData,
                                              0,
                                              `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`,
                                              "date"
                                            );
                                            if (value === null || !rowData.mbi) return;
                                            onUpdatePatient(updatedObj, 0);
                                            // onUpdatePatentData(column.column_name, rowData, 0, value,"date")
                                          }}
                                        />
                                      ) : (
                                        <Input
                                          id={`${column.column_name}-${index}`}
                                          defaultValue={rowData[column.column_name]}
                                          onKeyDown={(e) => {
                                            handleKeyDown(e, rowData, index);
                                          }}
                                          onBlur={(e) => {
                                            // console.log("on blur 1732 =",e)
                                            handleKeyDown(e, rowData, index);
                                          }}
                                          onChange={(value, e) => {
                                            onUpdatePatentData(
                                              column.column_name,
                                              rowData,
                                              0,
                                              value
                                            );
                                          }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <div
                                      className={`${
                                        rowData["marked"] === false
                                          ? "text-neutral-400"
                                          : ""
                                      }`}
                                    >
                                      {column.data_type !== "date"
                                        ? rowData[column.column_name]
                                        : 
                                        (rowData[column.column_name] && rowData[column.column_name].indexOf('T') !== -1)
                                          ? moment(rowData[column.column_name]).format("MM-DD-yyyy HH:mm A")
                                          : rowData[column.column_name]}
                                    </div>
                                  )}
                                </div>
                              )}
                              {column.column_name !== "marked" ? (
                                <div
                                  class="edit"
                                  onClick={() => editData(column, index)}
                                >
                                  {uneditable.indexOf(column.column_name) ===
                                    -1 &&
                                  !(
                                    editableRow.row === index &&
                                    editableRow.column === column.position
                                  ) &&
                                  !addedNewRow ? (
                                    <ChartIcon size="1.5em" />
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                      </Cell>
                    </Column>
                  );
                })}

              {/*  <Column align="center" key="AddCustom"  fixed="right" width={150}>
                        <HeaderCell>
                            <div onClick={() => {setShowCustomModal(true);}} className="font-bold cursor-pointer bg-white px-1 py-2 rounded-md text-orange-200 text-xs border border-solid border-orange-200">{`(+) Add Custom`}</div>
                        </HeaderCell>
                        <Cell dataKey="custom">
                            {
                                (rowData, index) => {
                                    // return  <div onClick={() => onUpdatePatient(rowData, index)} className="font-bold cursor-pointer bg-white px-1 py-2 rounded-md text-orange-200 text-xs border border-solid border-orange-200">{`Save`}</div>
                                    return  <div className="mt-2 italic text-gray-300 text-xs ">{`Birthdday, Hobbies, etc...`}</div>
                                }
                            }
                           
                        </Cell> 
                    </Column>
                    <Column align="center" key="AddCustom"  fixed="right" width={120}>
                        <HeaderCell>Actions</HeaderCell>
                        <Cell dataKey="custom">
                            {
                                (rowData, index) => {
                                    return  (
                                    <div className="flex gap-x-2 justify-center mt-2">
                                        {
                                            (editableRow !== index) && (
                                                <div className="cursor-pointer text-orange-200 font-bold hover:underline text-xs" onClick={() => {addRow(index)}}>Add</div>
                                            )
                                        }
                                        <div className="cursor-pointer text-orange-200 font-bold hover:underline text-xs" 
                                        onClick={() => {
                                            (editableRow === index) ? onUpdatePatient(rowData, index) : setEditableRow(index)
                                        }} >{(editableRow === index) ? 'Save' : 'Edit'}</div>
                                        {
                                            (editableRow === index) ?
                                            <div className="cursor-pointer text-orange-200 font-bold hover:underline text-xs" onClick={() => {onDiscard(rowData, index)}}>Discard</div> 
                                            :  <div className="cursor-pointer text-orange-200 font-bold hover:underline text-xs" onClick={() => {onDeletePatient(rowData, index)}}>Delete</div>
                                        }
                                       
                                    </div>)
                                }
                            }
                           
                        </Cell> 
                        </Column> */}
            </Table>
            {patientList.length < 1 && !loading && (
              <div
                className="flex justify-center relative z-100"
                style={{ marginTop: "-15rem" }}
              >
                <div>
                  <div className="text-orange-200">No records added.</div>
                  <div
                    onClick={() => {
                      if(folders.length < 1){
                          toast("Please create a folder to add records!",{type:"error"});
                          return;
                      }
                      addRow(0);
                      setEditableRow({
                        row: 0,
                        column: null,
                      });
                      setAddedNewRow(true);
                      //     addRow(0);setEditableRow({
                      //     row:null,
                      //     column:null
                      // });
                    }}
                    className="mt-2 font-bold cursor-pointer bg-white px-4 py-2 rounded-md text-orange-200 text-xs border border-solid border-orange-200 justify-center text-center"
                  >{`Add Record`}</div>
                </div>
              </div>
            )}
            {/* {loading && <FixedLoader />} */}
          </div>
        </div>
      </div>
      <AddColumnModal
        isVisible={showCustomModal}
        title="Add Custom Column"
        onSave={onAddCustom}
        onClose={() => setShowCustomModal(false)}
        defaultAvailableColumns={defaultAvailableColumns}
        uneditable={uneditable}
        tableColumn={tableColumn}
      />
      <UploadFileModal
        isVisible={showUploadModal}
        selectedFolder={selectedFolder}
        mbiHeader={mbiHeader}
        setMbiHeader={setMbiHeader}
        dobHeader={dobHeader}
        setDobHeader={setDobHeader}
        setSelectedDateFormat={setSelectedDateFormat}
        selectedDateFormat={selectedDateFormat}
        folders = {folders}
        setSelectedFolder={setSelectedFolder}
        setSelectedFolderForUpload={setSelectedFolderForUpload}
        selectedFolderForUpload={selectedFolderForUpload}
        headers={headers}
        title="Import"
        onSave={onImport}
        onClose={() => setShowUploadModal(false)}
        defaultAvailableColumns={defaultAvailableColumns}
      />
      {/* <AuthenticatePatientModal
                isVisible={showAuthenticateModal}
                title=""
                selectedRow={selectedRow}
                onSave={onRowAuthenticate}
                setShowAuthenticateEmailModal={setShowAuthenticateEmailModal}
                onClose={() => setShowAuthenticateModal(false)}
                defaultAvailableColumns = {defaultAvailableColumns}
               
            /> */}
      {/* <AuthenticatePatientEmailModal 
                isVisible={showAuthenticateEmailModal}
                title=""
                selectedRow={selectedRow}
                onSave={onRowAuthenticate}
                setShowAuthenticateModal={setShowAuthenticateModal}
                onClose={() => setShowAuthenticateEmailModal(false)}
                defaultAvailableColumns = {defaultAvailableColumns}
           
            /> */}
      <ConfirmationModel
        isVisible={showConfirmationModal}
        title=""
        message={
          selected.length > 0 &&
          (!selectAll || (selectAll && selectType != "all"))
            ? "Are you sure you want to delete selected records?"
            : "Are you sure you want to delete all records?"
        }
        onSave={onDeleteAllPatient}
        onClose={() => setShowConfirmationModal(false)}
        // defaultAvailableColumns = {defaultAvailableColumns}
      />
      <ConfirmationModel
        isVisible={showFetchConfirmationModal}
        title=""
        message={
          <div>
            Please confirm that you have asked and the selected beneficiaries
            have responded “Yes” to the following:
            <br />
            <br />
            "It is important that the information provided on an enrollment form
            is correct to the best of your knowledge. In an attempt to avoid
            delays of your enrollment application being processed, we would like
            to verify your information with CMS prior to enrollment submission.
            <br />
            <br />
            Please note that during this validation, CMS may provide additional
            details regarding your eligibility, which could assist your licensed
            sales agent with choosing the plan that best fits your needs. Do we
            have your consent to complete this verification?"
          </div>
        }
        onSave={
          (selectAll && selected.length > 50) || selected.length > 50
            ? onBatchProcessing
            : onGroupAuthenticate
        }
        onClose={() => setShowFetchConfirmationModal(false)}
        continueName="Fetch"
        cancelName="Cancel"
        // defaultAvailableColumns = {defaultAvailableColumns}
      />
      <ConfirmationModel
        isVisible={isModalOpen}
        title=""
        message={<div>Are you sure you want to delete this folder?</div>}
        onSave={() => {
        //   getFolderList();
            deleteFolder()
            setIsEditing(-1);
            setIsModalOpen(false);
        }}
        onClose={() => setIsModalOpen(false)}
        continueName="Delete"
        cancelName="No"
        // defaultAvailableColumns = {defaultAvailableColumns}
      />
      <ConfirmationModel
                isVisible={showExportConfirmationModal}
                title="Disclosure of Export"
                message ={<div className="font-medium font-Poppins"><p>Agents who choose to download their entire Book of Business or a portion thereof must take responsibility for ensuring that the data is securely stored, not shared with unauthorized individuals or entities, and not used in a way that contradicts CMS, federal, and state laws and regulations.</p>
                  <p>Protected Health Information (PHI) should only be disclosed to entities (such as employees or software providers) with whom you have executed a Business Associate Agreement (BAA). As a MediZues user, you already agreed to our BAA during registration. Be sure to do the same when dealing with your CRM.</p></div>}
                onSave={()=>onExport()}
                onClose={() => setShowExportConfirmationModal(false)}
                continueName="I Agree"
               
            />
        <ConfirmationModel
                isVisible={showPushConfirmationModal}
                title="Disclosure of Export"
                message ={<div className="font-medium font-Poppins"><p>Agents who choose to download their entire Book of Business or a portion thereof must take responsibility for ensuring that the data is securely stored, not shared with unauthorized individuals or entities, and not used in a way that contradicts CMS, federal, and state laws and regulations.</p>
                <p>Protected Health Information (PHI) should only be disclosed to entities (such as employees or software providers) with whom you have executed a Business Associate Agreement (BAA). As a MediZues user, you already agreed to our BAA during registration. Be sure to do the same when dealing with your CRM.</p></div>}
                onSave={()=>setshowPushDataModal(true)}
                onClose={() => setShowPushConfirmationModal(false)}
                continueName="I Agree"
               
            />
      <PushDataModel
        isVisible={showPushDataModal}
        title=""
        allWebhooks={webhooks}
        message={
          <div className="font-extrabold mb-10">
            Push To Webhook
          </div>
        }
        onCreate={createLink}
        onUpdate={updateLink}
        onDelete={deleteLink}
        onPushToWebhooks={onPushToWebhooks}
        // onSave={
        //   (selectAll && selected.length > 50) || selected.length > 50
        //     ? onBatchProcessing
        //     : onGroupAuthenticate
        // }
        onClose={() => setshowPushDataModal(false)}
        continueName="Push"
        cancelName="Cancel"
        // defaultAvailableColumns = {defaultAvailableColumns}
      />

      <MoveDataModel
      isVisible={showMoveDataModal}
      title=""
      folders={folders}
      selectedFolder={selectedFolder ? selectedFolder : {}}
      message={
        <div className="font-extrabold mb-10">
          Select Folder
        </div>
      }
      onSelect={moveFolder}
      onClose={() => setshowMoveDataModal(false)}
      cancelName="Cancel"
      continueName="Move"
    />
       
    </div>
  );
};
