import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'rsuite/dist/rsuite.min.css';
import './custom-theme.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureAppStore } from './store/configureStore';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const { store } = configureAppStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const options = {
    // passing the client secret obtained from the server
    // clientSecret: process.env.REACT_APP_STRIPE_SECRET,
};

root.render(
  <Provider store={store} options={options}>
  <Elements stripe={stripePromise}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar={true}/>
      </BrowserRouter>
    </React.StrictMode>
    </GoogleOAuthProvider>
    </Elements>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
